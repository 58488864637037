<template>
  <div v-if="hideButton" class="feedback-container">
    <dropdown-wrap-vue @onClickOutside="onClickOutside">
      <div class="feedback-button-container">
        <b-button @click="toggleFeedbackModel" class="feedback-button"></b-button>
        <div v-if="feedbackSuccess" class="feedback-success-message">
          <p>Thank you for the feedback</p>
        </div>
        <div v-if="open" class="feedback-form">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492" @click="toggleFeedbackModel" v-bind:class="'close-button'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M300.188 246L484.14 62.04c5.06-5.064 7.852-11.82 7.86-19.024 0-7.208-2.792-13.972-7.86-19.028L468.02 7.872C462.952 2.796 456.196.016 448.984.016c-7.2 0-13.956 2.78-19.024 7.856L246.008 191.82 62.048 7.872C56.988 2.796 50.228.016 43.02.016c-7.2 0-13.96 2.78-19.02 7.856L7.872 23.988c-10.496 10.496-10.496 27.568 0 38.052L191.828 246 7.872 429.952C2.808 435.024.02 441.78.02 448.984c0 7.204 2.788 13.96 7.852 19.028l16.124 16.116c5.06 5.072 11.824 7.856 19.02 7.856 7.208 0 13.968-2.784 19.028-7.856l183.96-183.952 183.952 183.952c5.068 5.072 11.824 7.856 19.024 7.856h.008c7.204 0 13.96-2.784 19.028-7.856l16.12-16.116c5.06-5.064 7.852-11.824 7.852-19.028 0-7.204-2.792-13.96-7.852-19.028L300.188 246z"/></svg>
          <div class="form-block">
            <div class="form-header">
              <p class="header-title">Send us your feedback!</p>
              <div>
                <p>Do you have a suggestion or found some bug?</p>
                <p>Let us know in the field below.</p>
              </div>
            </div>
            <div class="field-block">
              <b-input
                v-model="feedback"
                rows="4"
                @input="handleFeedbackInput()"
                placeholder="Describe your experience here..."
                type="textarea"></b-input>
                <div v-if="(!isFeedbackValid) && showError" class="required-error">
                  feedback is required *
                </div>
              <div v-if="file && file.name" class="selected-file">
                <p>{{ file.name }}</p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492" @click="removeSelectedFile" v-bind:class="'close-tag'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M300.188 246L484.14 62.04c5.06-5.064 7.852-11.82 7.86-19.024 0-7.208-2.792-13.972-7.86-19.028L468.02 7.872C462.952 2.796 456.196.016 448.984.016c-7.2 0-13.956 2.78-19.024 7.856L246.008 191.82 62.048 7.872C56.988 2.796 50.228.016 43.02.016c-7.2 0-13.96 2.78-19.02 7.856L7.872 23.988c-10.496 10.496-10.496 27.568 0 38.052L191.828 246 7.872 429.952C2.808 435.024.02 441.78.02 448.984c0 7.204 2.788 13.96 7.852 19.028l16.124 16.116c5.06 5.072 11.824 7.856 19.02 7.856 7.208 0 13.968-2.784 19.028-7.856l183.96-183.952 183.952 183.952c5.068 5.072 11.824 7.856 19.024 7.856h.008c7.204 0 13.96-2.784 19.028-7.856l16.12-16.116c5.06-5.064 7.852-11.824 7.852-19.028 0-7.204-2.792-13.96-7.852-19.028L300.188 246z"/></svg>
              </div>
            </div>
            <div class="footer-group-button">
              <b-field class="file" :class="['custom-file']">
                <b-upload
                  accept="image/jpeg, image/png"
                  v-model="file"
                  class="file-label">
                  <span class="file-cta">
                    <span class="file-label">Upload Image</span>
                  </span>
                </b-upload>
              </b-field>
              <div v-if="isImageSizeInvalid" class="required-size-error">
                  maximum upload file size 5MB *
              </div>
              <div class="submit-button">
                <b-button :disabled="loading" @click="submit">
                  <div v-if="loading" class="loader-container">
                    <div class="inava-loader"></div>
                  </div>
                  <p v-else>Send Feedback</p>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dropdown-wrap-vue>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DropdownWrapVue from '../Common/DropdownWrap.vue'
import isEmpty from 'lodash/isEmpty'

/**
 * This component is used to mail user feedback to SGA. It can accept text and images
 */
export default {
  name: 'FeedbackForm',
  components: {
    DropdownWrapVue
  },
  data () {
    return {
      open: false,
      hideButton: true,
      feedback: '',
      loading: false,
      file: null,
      imageData: '',
      feedbackSuccess: false,
      imageName: null,
      imageSize: null,
      isFeedbackValid: false,
      showError: false,
      isImageSizeInvalid: false
    }
  },
  computed: {
    ...mapState('user', ['userDetails'])
  },
  watch: {
    file () {
      if (this.file.name != null) {
        const reader = new FileReader()
        // validating the size of the file should be less than 5Mb
        const FILESIZE_MAX = 5 * 1024 * 1024
        this.imageSize = this.file.size
        if (this.imageSize <= FILESIZE_MAX) {
          this.isImageSizeInvalid = false
          reader.readAsDataURL(this.file)
          reader.onload = (e) => {
            this.imageData = e.target.result
            this.imageName = this.file.name
          }
        } else {
          // show error that file is greater than 5Mb
          this.isImageSizeInvalid = true
          this.file = {}
        }
      }
    }

  },
  methods: {
    ...mapActions('feedback', ['postFeedback']),
    /** This method is used to open the component
     * @public
     */
    toggleFeedbackModel () {
      this.open = !this.open
      this.feedbackSuccess = false
      this.showError = false
      this.isFeedbackValid = false
      this.feedback = ''
      this.file = {}
      this.imageData = {}
      this.isImageSizeInvalid = false
    },

    /** This method is used to close the component if a click is detected outside its boundaries
     * @public
     */
    onClickOutside (event) {
      this.open = false
    },
    /**
     * This method is used to remove the uploaded image
     * @public
     */
    removeSelectedFile () {
      this.file = {}
      this.imageData = ''
      this.imageName = null
    },
    /**
     * This method is used to submit the contents of the feedback form
     * @public
     */
    async submit () {
      this.showError = true
      if (this.isFeedbackValid && !this.isImageSizeInvalid) {
        this.loading = true
        const data = {
          image: this.imageData,
          feedback: this.feedback,
          userName:
            this.userDetails.firstName + ' ' + this.userDetails.lastName,
          url: window.location.href,
          imageName: this.imageName,
          imageSize: this.imageSize
        }
        const res = await this.postFeedback(data)
        this.loading = false
        if (res === 200) {
          this.feedbackSuccess = true
          this.imageName = null
          this.open = false
          setTimeout(() => {
            this.feedbackSuccess = false
          }, 2000)
        }
      }
    },

    handleFeedbackInput () {
      this.showError = true
      if (!isEmpty((this.feedback.replace(/^\s*/, '')))) {
        this.isFeedbackValid = true
      } else {
        this.isFeedbackValid = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.required-error{
  margin-top: 106px;
}

.feedback-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 10;
  .feedback-button-container {
    position: relative;
    .feedback-success-message {
      position: absolute;
      width: 192px;
      right: 63px;
      bottom: 24px;
      height: 32px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #2780eb;
      p {
        // height: 17px;
        opacity: 0.9;
        margin: 0px;
        font-family: Quicksand, sans-serif;
        font-weight: 600;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.29;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
      }
    }
    .feedback-button {
      margin: 0px 25px 15px 0px;
      outline: none;
      border: none;
      // border-radius: 50%;
      // background-color: #7f8fa4;
      width: 47.5px;
      height: 43px;
      // padding: 0;
      // background: transparent;
      background: url('../../assets/satisfaction.svg');
      background-size: contain;
      filter: drop-shadow(0 3px 9px 0 rgba(0, 0, 0, 0.2));
      // border: 1px solid green;
      img {
        // object-fit: contain;
        width: 100%;
        // filter: drop-shadow(0 3px 9px 0 rgba(0, 0, 0, 0.2));
        height: 100%;
      }
      .cross-button {
        display: block;
        position: absolute;
        width: 192px;
        right: 63px;
        bottom: 24px;
        height: 32px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2780eb;
        p {
          // height: 17px;
          opacity: 0.9;
          margin: 0px;
          font-family: Quicksand, sans-serif;
          font-weight: 600;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.29;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff;
        }
      }
    }
    .feedback-form {
      position: absolute;
      right: 15px;
      width: 300px;
      height: 345px;
      bottom: 15px;
      background: white;
      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      .form-block {
        .selected-file {
          position: absolute;
          display: flex;
          align-items: center;
          top: 140px;
          left: 20px;
          right: 20px;
          .close-tag {
            width: 8px;
            cursor: pointer;
            height: 8px;
            path {
              fill: #888;
            }
          }
          p {
            max-width: 250px;
            font-size: 12px;
            padding-right: 2px;
            white-space: nowrap;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .footer-group-button {
          display: flex;
          padding: 16px 20px 0px;
          justify-content: space-between;
          .custom-file {
            margin: 0px;
            .file-cta {
              height: 32px;
              width: 120px;
              display: flex;
              justify-content: center;
              border-radius: 4px;
              background-color: #535eeb;
              color: #ffffff;
              font-family: Quicksand, sans-serif;
              font-weight: 600;
              font-size: 12px;
              opacity: 0.9;
              border: none;
            }
          }
          .submit-button {
            display: flex;
            flex: 0.5;
            justify-content: flex-end;
            .loader-container {
              display: flex;
              .inava-loader {
                position: relative;
                display: flex;
                margin: auto;
                border-radius: 50%;
                border: none;
                z-index: 900;
                width: 40px;
                height: 40px;
                background-image: url('../../assets/Inava-logo-GIF.gif');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
              }
              .loader {
                display: inline-block;
                width: 20px;
                height: 20px;
                border: 2px solid white;
                border-right-color: transparent;
                border-radius: 50%;
                -webkit-animation: spinner-border 0.75s linear infinite;
                animation: spin 0.75s linear infinite;
              }
              /* Safari */
              @-webkit-keyframes spin {
                0% {
                  -webkit-transform: rotate(0deg);
                }
                100% {
                  -webkit-transform: rotate(360deg);
                }
              }

              @keyframes spin {
                0% {
                  transform: rotate(0deg);
                }
                100% {
                  transform: rotate(360deg);
                }
              }
            }
            button {
              &[disabled] {
                opacity: 0.6;
              }
              display: flex;
              align-items: center;
              justify-content: center;
              width: 120px;
              outline: none;
              border: none;
              height: 32px;
              background-color: #00218a;
              padding: 5px;
              border-radius: 4px;
              p {
                opacity: 0.9;
                font-family: Quicksand, sans-serif;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: center;
                color: #ffffff;
                margin: 0px;
              }
              .spinner-border {
                color: #ffffff;
                width: 15px;
                height: 15px;
                border: 2px solid currentColor;
                border-right-color: transparent;
              }
            }
          }

          .required-size-error{
              color: red;
              position: absolute;
              font-size: 12px;
              margin-top: 30px;
              margin-left: 2px;
            }
        }
        .form-header {
          display: flex;
          flex-direction: column;
          background: #354052;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          padding: 20px;
          p {
            margin: 0px;
            color: white;
            font-size: 12px;
            font-family: Quicksand, sans-serif;
          }
          .header-title {
            font-weight: 500;
            padding: 10px 0px;
            font-size: 16px;
            font-family: Quicksand, sans-serif;
            font-weight: 600;
          }
        }
        .field-block {
          padding: 20px 20px;
          display: flex;
          position: relative;
          flex-direction: column;
          p {
            margin: 0px;
          }
          .control {
            margin: 0px;
          }
        }
      }
      .close-button {
        position: absolute;
        outline: none;
        width: 10px;
        height: 10px;
        fill: white;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>
