<template>
  <div class="cip-members">
    <img
      :src="client_logos && client_logos[type] ? client_logos[type] : ''"
      alt=""
      :class="[type]" @click="goToHome" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { fullScreenMode, getModuleToNavigate } from '@/util/util'
import isEmpty from 'lodash/isEmpty'
/** This component renders the company logos */
export default {
  name: 'CompanyPartner',
  props: ['type'],
  computed: {
    ...mapState('common', ['isCollapsed', 'reportFullscreen']),
    ...mapState('user', ['client_logos', 'userDetails'])
  },
  mounted () {
    if (isEmpty(this.client_logos)) {
      this.getClientLogos()
    }
  },
  methods: {
    ...mapActions('user', ['getClientLogos']),
    ...mapActions('common', ['resetReportFullscreen']),
    goToHome () {
      if (this.reportFullscreen.status) {
        fullScreenMode(document, 'off')
        this.resetReportFullscreen()
      }
      this.$router.replace(getModuleToNavigate(this.userDetails))
    }
  }
}
</script>

<style lang="scss" scoped>
.cip-members {
  cursor: pointer;
  .logo_dark {
    margin-left: 20px;
    max-width: 140px;
    max-height: 50px;
    object-fit: contain;

  }
  .logo_light {
    margin-left: 20px;
    max-width: 140px;
    max-height: 50px;
    object-fit: contain;
  }
}

.hidden {
  visibility: hidden;
}
</style>
