<template>
  <div class="background-main">
    <div v-if="!fullscreenActive">
      <header-vue />
    </div>
    <div v-if="currentModule === 'esg-daas'|| currentModule === 'investment-insight' || currentModule === 'playbook' || currentModule === 'data-management-platform' || $route.meta.hideSidePanel || (currentModule === 'reports-repository' && !this.isReportTypePresent(active_page) && $route.meta.hideSidePanel)" class="esg-daas-panel">
            <router-view />
    </div>
    <div v-else  class="seperate-panel" :class="[fullscreenActive && 'change-height']">
      <div class="flex-three" :class="[isCollapsed ? 'collapsed' : '']">
        <side-panel-filter-tam v-if="currentModule === 'tam'" />
        <side-panel-filter-voe v-else-if="currentModule === 'voe'" />
        <side-panel-filter v-else-if="currentModule === 'voc'" />
        <side-panel-filter-reports-repo v-else-if="currentModule === 'reports-repository'" />
        <side-panel-filter-esg v-else-if="currentModule === 'esg-controversies'" />
      </div>
      <div class="flex-seven" :class="[isCollapsed ? 'expanded' : '']">
        <div :class="['main-view']">
          <template v-if="$route.meta.group !== 'pageNotFound'">
            <div class="sub-header">
              <nested-top-navigation v-if="!fullscreenActive" />
              <page-tracking :withLegends="true" />
            </div>
          </template>
          <div class="padding-both-side">
            <router-view />
          </div>
        </div>
      </div>
    </div>
    <feedback-form-vue v-if="!fullscreenActive" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TopNavigation from '@/components/Common/Navigation/TopNavigation.vue'
import NestedTopNavigation from '@/components/Common/Navigation/NestedTopNavigation.vue'
import TopNavigationVoe from '@/components/Common/Navigation/TopNavigationVoe.vue'
import MultiModuleTopNav from '@/components/Common/Navigation/MultiModuleTopNavigation.vue'
import SidePanelFilter from '@/components/Common/SidePanel/SidePanelFilter.vue'
import SidePanelFilterTam from '@/components/Common/SidePanel/SidePanelFilterTam.vue'
import SidePanelFilterVoe from '@/components/Common/SidePanel/SidePanelFilterVoe.vue'
import SidePanelFilterEsg from '@/components/Common/SidePanel/SidePanelFilterEsg.vue'
import SidePanelFilterReportsRepo from '@/components/Common/SidePanel/SidePanelFilterReportsRepo.vue'
import FeedbackFormVue from '@/components/FeedbackForm/FeedbackForm.vue'
import HeaderVue from '@/components/Header/Header.vue'
import LocalTabsVue from '@/components/LocalTabs/LocalTabs.vue'
import PageTracking from '@/components/PageTracking/PageTracking.vue'

/**
 * This is the parent component for all the views in the frontend.
 */
export default {
  name: 'MainPage',
  components: {
    PageTracking,
    HeaderVue,
    SidePanelFilter,
    SidePanelFilterTam,
    SidePanelFilterVoe,
    SidePanelFilterEsg,
    SidePanelFilterReportsRepo,
    FeedbackFormVue,
    TopNavigation,
    TopNavigationVoe,
    LocalTabsVue,
    NestedTopNavigation,
    MultiModuleTopNav
  },
  data () {
    return {
      statistics: [
        { name: 'Summary', alias: 'summary', light: require('../../assets/summary_light.svg'), dark: require('../../assets/summary_dark.svg') },
        { name: 'Reports', alias: 'reports', light: require('../../assets/report_light.svg'), dark: require('../../assets/report_dark.svg') },
        { name: 'NPS', alias: 'nps', light: require('../../assets/nps_light.svg'), dark: require('../../assets/nps_dark.svg') }],
      performance: [
        { name: 'CSAT Drivers', alias: 'csat', light: require('../../assets/csat_light.svg'), dark: require('../../assets/csat_dark.svg') },
        { name: 'Trending and Declining Drivers', alias: 'trends', light: require('../../assets/trending-light.svg'), dark: require('../../assets/trending-dark.svg') },
        { name: 'Competitive Positioning', alias: 'competitive-positioning', light: require('../../assets/winloss-light.svg'), dark: require('../../assets/winloss-dark.svg') }],
      withTabs: ['summary', 'reports', 'csat'],
      voeStatistics: [
        { name: 'Summary', alias: 'voeSummary', light: require('../../assets/summary_light.svg'), dark: require('../../assets/summary_dark.svg') },
        { name: 'Reports', alias: 'voeReports', light: require('../../assets/report_light.svg'), dark: require('../../assets/report_dark.svg') }],
      voePerformance: [
        { name: 'ESAT Drivers', alias: 'voeEsat', light: require('../../assets/csat_light.svg'), dark: require('../../assets/csat_dark.svg') },
        { name: 'Trending and Declining Drivers', alias: 'voeTrends', light: require('../../assets/trending-light.svg'), dark: require('../../assets/trending-dark.svg') }]

    }
  },
  computed: {
    ...mapState('common', ['isCollapsed', 'reportFullscreen']),
    ...mapState('filters', ['subscriptionRange', 'monthGranularity']),
    ...mapState('common', ['active_page', 'currentModule']),
    ...mapState('user', ['userDetails']),

    fullscreenActive () {
      return this.reportFullscreen.status
    },
    navbarVariant () {
      if (this.userDetails.modules.length <= 2 && this.userDetails.modules.map(d => d.module_name).includes('voc')) {
        return 'voc'
      } else if (this.userDetails.modules.length <= 2 && this.userDetails.modules.map(d => d.module_name).includes('voe')) {
        return 'voe'
      } else {
        return 'multi-module'
      }
    }
  },

  methods: {

    isReportTypePresent (reportName) {
      const reportModule = this.userDetails.modules.find(module => module.module_name === 'reports-repository')
      if (reportModule.moduleProperties && reportModule.moduleProperties.subTypes && reportModule.moduleProperties.subTypes.length > 0) {
        return reportModule.moduleProperties.subTypes.some(report => report.routeName === reportName)
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" src="./main.scss" />
