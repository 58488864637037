<template>
  <side-panel-vue>
    <FilterHeader v-if="active_page !== 'profile'" />
    <div
      v-if="active_page !== 'profile'"
      class="filter-container"
      :class="[isCollapsed ? 'collapsed' : '']"
    >
      <div class="each-dropdown">
        <SingleSelectorVue
          label="Category"
          placeholder="Select category"
          className="category-width"
          :list="
            categoryList.filter((c) => availableCategories.includes(c.name))
          "
          :selected="selectedCategories"
          :selectedNestedData="subCategories"
          :showNested="true"
          :showRadio="true"
          :updateAnalytics="true"
          @onChangeNestedData="onChangeNestedData"
          @onChange="onChangeCategory"
          :sidebarHeightApplied="true"
        />
      </div>
      <div class="each-dropdown">
        <ExtendibleMultiselector
          label="Region"
          :list="regionList"
          :selected="selectedCountries"
          @onChange="onChangeRegions"
          :sidebarHeightApplied="true"
        />
      </div>
      <div class="each-dropdown">
        <MultiSelectorVue
          label="Industry"
          placeholder="Select industry"
          :list="industries"
          :selected="selectedIndustries"
          @onChange="onChangeIndustry"
          :sidebarHeightApplied="true"
        />
      </div>
      <div class="each-dropdown">
        <MultiSelectorVue
          label="Business Size"
          placeholder="Select business size"
          :list="companies"
          :selected="selectedCompanies"
          @onChange="onChangeBusiness"
          :sidebarHeightApplied="true"
        />
      </div>
    </div>
  </side-panel-vue>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FilterHeader from '@/components/GlobalFilters/FilterHeader.vue'
import SingleSelectorVue from '../../Dropdowns/SingleSelector.vue'
import MultiSelectorVue from '../../Dropdowns/MultiSelector.vue'
import SidePanelVue from './SidePanel.vue'
import ExtendibleMultiselector from '../../Dropdowns/ExtendibleMultiselector.vue'

/** This component contains the filters in the side panel */
export default {
  name: 'GlobalFilters',
  components: {
    FilterHeader,
    SingleSelectorVue,
    MultiSelectorVue,
    SidePanelVue,
    ExtendibleMultiselector
  },
  data() {
    return {
      timePeriodList: [],
      toggle: false,
      monthPickerKey: 1,
      sidebarHeightApplied:true,
      availableCategories: [
        'Application Testing Services',
        'Business Intelligence',
        'Managed Security Services'
      ]
    }
  },
  computed: {
    ...mapState('filters', [
      'categoryList',
      'selectedCategories',
      'industries',
      'selectedIndustries',
      'companies',
      'selectedCompanies',
      'isInitialLoadDone',
      'subCategories',
      'regionList',
      'selectedCountries'
    ]),
    ...mapState('user', ['userDetails']),
    ...mapState('common', ['active_page', 'isCollapsed', 'currentModule'])
  },
  watch: {
    subCategories() {
      if (this.subCategories.length) {
        this.getYears(this.subCategories)
      }
    }
  },
  beforeDestroy() {
    this.resetSubCategories()
  },
  mounted() {
    if (this.userDetails) {
      this.getDataForGlobalFilters({currentModule: this.currentModule})
    }
  },
  methods: {
    ...mapActions('filters', [
      'getDataForGlobalFilters',
      'updateSelectedCategory',
      'updateSelectedIndustries',
      'updateSelectedCompanies',
      'updateSelectedCountries',
      'updateSelectedRegions',
      'getSourceByCategoryId',
      'resetCategoryDependent',
      'updateIsInitialLoadDone',
      'updateSelectedNestedCategories',
      'resetSubCategories'
    ]),
    ...mapActions('tam', ['getYears']),
    /** This event handler updates the selected category
     * @public
     */
    onChangeCategory(data) {
      this.resetCategoryDependent()
      this.updateSelectedCategory(data)
    },
    /** This event handler updates the selected industries
     * @public
     */
    onChangeIndustry(data) {
      this.updateSelectedIndustries(data)
    },
    /** This event handler updates the selected company sizes
     * @public
     */
    onChangeBusiness(data) {
      this.updateSelectedCompanies(data)
    },
    /** This event handler updates the selected nested categories
     * @public
     */
    onChangeNestedData(data) {
      this.updateSelectedNestedCategories(data)
    },
    onChangeRegions(countryList, regionList) {
      this.updateSelectedCountries(countryList)
    }
  }
}
</script>

<style lang="scss" scoped>
.side-panel-container {
  height: 100%;
  background: var(--sidebar-background-color);
  .product-image {
    background-color: #12151a;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    img {
      width: 60px;
      object-fit: contain;
      height: 100%;
    }
  }
  .side-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .filter-container {
      overflow-y: auto;
      .each-dropdown {
        padding: 10px 20px;
        min-width: max-content; // This is to prevent displacement of filter text when the sidepanel is expanded
      }
    }
    .collapsed {
      // opacity: 0;
      display: none;
      // transition: 0.225s ease-out 0.075s;
    }
  }
}
</style>
