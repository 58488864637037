<template>
  <div class="tabs">
    <div class="tabs-flex left-group">
      <div
      v-if="userDetails.modules.map(d => d.module_name).includes('voc')"
      :title="titleList.vocTitle"
      @click="changeTab(0)"
      :class="['tab', active == 0 ? 'active' : '']">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#7f8fa4" d="M0 12v-2a2 2 0 012-2h16a2 2 0 012 2v2a2 2 0 01-2 2H2a2 2 0 01-2-2zm18-2H2v2h16zM0 4V2a2 2 0 012-2h16a2 2 0 012 2v2a2 2 0 01-2 2H2a2 2 0 01-2-2zm18-2H2v2h16z"/></svg>
        <p>{{ 'VoC Statistics' }}</p>
        <div v-if="active == 0" class="overlap-tab"></div>
      </div>
    </div>
    <div class="tabs-flex right-group">
      <div
        v-if="userDetails.modules.map(d => d.module_name).includes('voc')"
        :title="titleList.csatDriversTitle"
        @click="changeTab(3)"
        :class="['tab', active == 3 ? 'active' : '']">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2 18a2 2 0 01-2-2V2a2 2 0 012-2h18a2 2 0 012 2v14a2 2 0 01-2 2zm0-2h18V2H2zm12-2V7h2v7zm-4 0V4h2v10zm-4 0V8h2v6z" fill="#9db4d1"/></svg>
        <p>{{ 'VoC Performance Drivers' }}</p>
        <div  v-if="active == 3" class="overlap-tab"></div>
      </div>
    <div
      v-if="userDetails.modules.map(d => d.module_name).includes('voc')"
      :title="titleList.commentsTitle"
      @click="changeTab(4)"
      :class="['tab', active == 4 ? 'active' : '']">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#7f8fa4" d="M18.2 3.8v10.8H6.32l-.54.5-1.98 1.885V3.8h14.4m.9-1.8H2.9a.9.9 0 00-.9.9v16.2a.891.891 0 001.53.63l3.51-3.33H19.1a.9.9 0 00.9-.9V2.9a.9.9 0 00-.9-.9z"/></svg>
      <p>{{ 'Comments' }}</p>
      <div  v-if="active == 4" class="overlap-tab"></div>
    </div>
    <!-- <div class="group secondary-color"></div> -->
    <!-- <div
      v-if="userDetails.modules.includes('marketing')"
      @click="changeTab(6)"
      :class="['tab', active == 6 ? 'active' : '']">
      <p>{{ 'Marketing' }}</p>
      <div  v-if="active == 6" class="overlap-tab"></div>
    </div> -->
    <div
      v-if="userDetails.modules.map(d => d.module_name).includes('tam')"
      :title="titleList.tamTitle"
      @click="changeTab(7)"
      :class="['tab', active == 7 ? 'active' : '']">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#7f8fa4" d="M18.2 3.8v10.8H6.32l-.54.5-1.98 1.885V3.8h14.4m.9-1.8H2.9a.9.9 0 00-.9.9v16.2a.891.891 0 001.53.63l3.51-3.33H19.1a.9.9 0 00.9-.9V2.9a.9.9 0 00-.9-.9z"/></svg>
      <p>Customer Adoption Trends</p>
      <div  v-if="active == 7" class="overlap-tab"></div>
    </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

/**
 * This component is a child of the Header component. It contains the tabs for navigation
 */
export default {
  name: 'TopBarNav',
  data () {
    return {
      active: null,
      tabs: ['/voc/', '/reports', '/nps', '/voc-drivers/', '/comments', '/trends', '/marketing', '/tam'],
      titleList: {
        vocTitle: 'Measure & Track | Bird\'s eye view of\ncompetitive ratings and review volumes',
        reportTitle: 'Measure and Track | Granular reports by\ncompetitor, source and time-points',
        csatDriversTitle: 'Deep-dive on the core critical capabilities\nfor the segment',
        commentsTitle: 'Deep-dive on the qualitative insights\nfrom actual comment verbatim',
        tamTitle: 'Measure and track adoption trends\nto precisely determine addressable\nand obtainable market outcomes.'
      }
    }
  },
  computed: {
    ...mapState('user', ['userDetails'])
  },
  watch: {
    $route (to, from) {
      if (to !== from) {
        this.updateActivePage(to.name)
        this.updateActiveTab()
        this.updateAnalyticsEvent()
        // if (to.name !== 'comments') {
        //   // this.updatecustomSortedAttributes([])
        //   this.updateSelectedAttribute([])
        // }
        if (to.meta.module !== from.meta.module) {
          this.updatecustomSortedAttributes([])
          this.updateSelectedAttribute([])
          this.updateSelectedSSTopic(null)
        }
      }
    }
  },
  mounted () {
    this.updateActivePage(this.$route.name)
    this.updateActiveTab()
    this.updateAnalyticsEvent()
  },
  methods: {
    ...mapActions('analytics', ['updateEvent']),
    ...mapActions('common', ['updateActivePage']),
    ...mapActions('sunriseSunset', [
      'updatecustomSortedAttributes',
      'updateSelectedAttribute',
      'updateSelectedSSTopic'
    ]),
    /** This method updates active tab.
     * @public
     */
    updateActiveTab () {
      // this.active = this.tabs.indexOf(this.tabs.filter((tab) => this.$route.path.startsWith(tab))[0])
      this.active = this.tabs.indexOf(this.tabs.find((tab) => this.$route.path.startsWith(tab)))
    },
    /** This method calls the **changePath()** method and updates the active tab
     * @public
     */
    changeTab (index) {
      this.changePath(index)
      this.active = index
    },
    /** This method programmatically routes the user to the page which the clicked tab refers to
     * @param path {Number}
     * @values 0, 3, 4
     * @public
     */
    changePath (path) {
      if (this.tabs[path] === 'home') {
        this.$router.push('/home')
      } else {
        this.$router.push(this.tabs[path])
      }
    },
    /** This method updates the analytics event
     * @public
     */
    updateAnalyticsEvent () {
      this.updateEvent({
        userId: this.userDetails.userId,
        event: 'page_view',
        label: '',
        pageUrl: window.location.href
      })
    },
    /** This method emits the onChange event
     * @public
     */
    openTab () {
      this.$emit('onChange')
    }
  }
}
</script>

<style lang="scss" src="./TopNavigation.scss" scoped />
