<template>
  <div class="tabs multi-module">
    <div class="tabs-flex">
      <div class="dropdown-tab" v-if="userDetails.modules.map(m => m.module_name).includes('voc')">
        <div class="tab" data-module="voc">
          <p>Voice of Customer</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:class="'icons'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#a8aab7" d="M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"/></svg>
          <div class="overlap-tab"></div>
        </div>
        <ul class="dropdown-list">
            <div class="dropdown-header" :title="titleList.vocTitle">Statistics</div>
            <div class="dropdown-options">
              <router-link class="list-item" :to="{ name: 'summary' }">Summary</router-link>
              <router-link class="list-item" :to="{ name: 'reports' }">Reports</router-link>
              <router-link class="list-item" :to="{ name: 'nps' }">NPS</router-link>
            </div>
            <div class="dropdown-header" :title="titleList.csatDriversTitle">Performance Drivers</div>
            <div class="dropdown-options">
              <router-link class="list-item" :to="{ name: 'csat' }">CSAT</router-link>
              <router-link class="list-item" :to="{ name: 'trends' }">Trending & Declining</router-link>
              <router-link class="list-item" :to="{ name: 'competitive-positioning' }">Competitive Positioning</router-link>
            </div>
            <div class="dropdown-header" :title="titleList.commentsTitle">Comments</div>
            <div class="dropdown-options">
              <router-link class="list-item" :to="{ name: 'comments' }">Comments</router-link>
            </div>
          </ul>
      </div>
      <div class="tab" data-module="tam" v-if="userDetails.modules.map(m => m.module_name).includes('tam')">
        <router-link :to="{ name: 'tam' }">
          <p>Customer Adoption Trends</p>
          <div class="overlap-tab"></div>
        </router-link>
      </div>
      <div class="dropdown-tab" v-if="userDetails.modules.map(m => m.module_name).includes('voe')">
        <div class="tab" data-module="voe">
          <p>Voice of Employee</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:class="'icons'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#a8aab7" d="M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"/></svg>
          <div class="overlap-tab"></div>
        </div>
        <ul class="dropdown-list" data-module="voe">
          <div class="dropdown-header" :title="titleList.csatDriversTitle">Performance Drivers</div>
            <div class="dropdown-options">
              <router-link class="list-item" :to="{ name: 'voeTrends' }">Emerging - Critical Priorities</router-link>
              <router-link class="list-item" :to="{ name: 'voeEsat' }">Employee Satisfaction (ESAT) Measure</router-link>
              <router-link class="list-item" :to="{ name: 'voeEvpMatrix' }">Employee Value Proposition (EVP) Matrix</router-link>
              <!-- <router-link class="list-item" :to="{ name: 'competitive-positioning' }">Competitive Positioning</router-link> -->
            </div>
            <div class="dropdown-header" :title="titleList.vocTitle">Statistics</div>
            <div class="dropdown-options">
              <router-link class="list-item" :to="{ name: 'voeReports' }">Perception Watch</router-link>
              <router-link class="list-item" :to="{ name: 'voeSummary' }">Employee Satisfaction Index (ESI)</router-link>
            <!-- </div> -->
              <!-- <router-link class="list-item" :to="{ name: 'nps' }">NPS</router-link> -->
            </div>
            <div class="dropdown-header" :title="titleList.commentsTitle">Comments</div>
            <div class="dropdown-options">
              <router-link class="list-item" :to="{ name: 'voeComments' }">Employee Experience Cognition</router-link>
            </div>
          </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'MultiModuleTopNav',
  data () {
    return {
      titleList: {
        vocTitle: 'Measure & Track | Bird\'s eye view of\ncompetitive ratings and review volumes',
        reportTitle: 'Measure and Track | Granular reports by\ncompetitor, source and time-points',
        csatDriversTitle: 'Deep-dive on the core critical capabilities\nfor the segment',
        commentsTitle: 'Deep-dive on the qualitative insights\nfrom actual comment verbatim'
      }
    }
  },
  computed: {
    ...mapState('user', ['userDetails'])
  },
  watch: {
    $route (to, from) {
      if (to !== from) {
        this.updateActiveTab(to)
        this.updateAnalyticsEvent()
        this.updateActivePage(to.name)
        if (to.meta.module !== from.meta.module) {
          this.updatecustomSortedAttributes([])
          this.updateSelectedAttribute([])
          this.updateSelectedSSTopic(null)
        }
      }
    }
  },
  mounted () {
    this.updateAnalyticsEvent()
    this.updateActiveTab(this.$route)
    this.updateActivePage(this.$route.name)
  },
  methods: {
    ...mapActions('analytics', ['updateEvent']),
    ...mapActions('common', ['updateActivePage', 'updateActiveModule']),
    ...mapActions('sunriseSunset', [
      'updatecustomSortedAttributes',
      'updateSelectedAttribute',
      'updateSelectedSSTopic'
    ]),
    /** This method updates active tab.
     * @public
     */
    updateActiveTab (route) {
      const tabs = document.querySelectorAll('.tab')
      tabs.forEach(tab => {
        if (tab.dataset.module === route.meta.module) {
          tab.classList.add('active')
        } else {
          tab.classList.remove('active')
        }
      })
    },
    /** This method updates the analytics event
     * @public
     */
    updateAnalyticsEvent () {
      this.updateEvent({
        userId: this.userDetails.userId,
        event: 'page_view',
        label: '',
        pageUrl: window.location.href
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: inline-flex;
  margin-left: 20px;
  position: relative;
  z-index: 50;
  pointer-events: none;
  width: 100%;
  .tabs-flex {
    display: flex;
    position: relative;
  }
  .group {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 4px;
  }
  .dropdown-tab {
    margin-left: 10px;
    position: relative;
    display: flex;
    align-self: flex-start;
    z-index: 15;
    .tab {
      margin: 0;
      pointer-events: all;
    }
    .active {
      p {
        color: #3c90f5;
        font-weight: bold;
      }
      .icons path {
        fill: #3c90f5;
      }
      .overlap-tab {
        opacity: 1;
      }
    }
    &:hover {
      height: 400px;
      .dropdown-list {
        display: block;
      }
      .tab {
        pointer-events: all;
        p {
          color: #3c90f5;
          font-weight: bold;
        }
        .icons path {
          fill: #3c90f5;
        }
        .overlap-tab {
          opacity: 1;
        }
      }
    }
  }
  .active a{
    p {
      color: #3c90f5;
      font-weight: bold;
    }
    .icons path {
      fill: #3c90f5;
    }
    .overlap-tab {
      opacity: 1;
    }
  }
  .tab {
    pointer-events: all;
    height: 55px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px;
    cursor: pointer;
    position: relative;
    a {
      padding: 0;
      margin: 0;
      border: none !important;
      height: 100%;
    }
    &:hover {
      p {
        color: #3c90f5;
        font-weight: bold;
      }
      .icons path {
        fill: #3c90f5;
      }
      .overlap-tab {
        opacity: 1;
      }
      .dropdown-list {
        display: block;
      }
    }
    .overlap-tab {
      position: absolute;
      top: 0px;
      width: 100%;
      height: 7px;
      z-index: 1;
      opacity: 0;
      background-color: #3c90f5;
    }
    .icons {
      display: flex;
      margin: 0px;
      margin-left: 5px;
      path {
        fill: #7f8fa4;
      }
    }
    p {
      margin: 0px;
      font-family: Quicksand;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #7f8fa4;
      cursor: pointer;
    }
  }
}

.dropdown-list {
  font-family: 'Quicksand';
  position: fixed;
  position: absolute;
  pointer-events: all;
  background: var(--dropdown-backgroud);
  box-shadow: 0px 6px 18px #00000033;
  border-radius: 4px;
  left: 335px;
  left: 0;
  display: none;
  width: 250px;
  padding: 0px 0px;
  z-index: 35;
  top: 45px;
  max-height: 380px;
  overflow-x: hidden;
  border: none !important;
  .dropdown-header {
    font-size: 14px;
    font-weight: 600;
    font-family: Quicksand;
    color: #A8AAB7;
    padding: 5px 15px;
    &:first-of-type {
      padding-top: 15px;
    }
  }
  .dropdown-options {
    color: var(--description);
    &:last-child {
      a.list-item {
        padding-bottom: 10px;
      }
    }
    a.list-item {
      color: unset;
      border: none !important;
      margin: 0;
      justify-content: flex-start;
      font-size: 14px;
      display: flex;
      padding: 6px 15px 6px 15px;
      align-items: center;
      svg {
        fill: #c2c7de;
      }
      &:hover {
        background: var(--dropdown-background-hover);
      }
    }
  }
}

.tab .dropdown-header:nth-of-type(5) {
  & + .dropdown-options > a.list-item:last-child {
    padding-bottom: 15px;
  }
}

.dropdown-list[data-module="voe"] {
  width: 300px;
}
</style>
