<template>
  <side-panel-vue>
    <FilterHeader v-if="active_page !== 'profile'" />
    <div
      v-if="active_page !== 'profile'"
      class="filter-container"
      :class="[isCollapsed ? 'collapsed' : '']">
      <div class="each-dropdown" v-if="active_page !== 'vendor-csat'">
        <SingleSelectorVue
          label="Category"
          placeholder="Select category"
          className="category-width"
          :list="categoryList"
          :selected="selectedCategories"
          :selectedNestedData="subCategories"
          :showNested="true"
          :showRadio="true"
          :enabled="false"
          :sidebarHeightApplied="true"
          :updateAnalytics="true"
          @onChangeNestedData="onChangeNestedData"
          @onChange="onChangeCategory" />
      </div>
      <div class="each-dropdown" v-if="active_page !== 'vendor-csat'">
        <MultiSelectorVue
          label="Product"
          placeholder="Select product"
          :list="productList"
          :selected="selectedProducts"
          @onChange="onChangeProduct"
          :sidebarHeightApplied="true" />
      </div>
      <div class="each-dropdown" v-if="active_page === 'vendor-csat'">
        <MultiSelectorVue
          label="Vendor"
          placeholder="Select vendor"
          :list="csatVendorList"
          :selected="selectedCsatVendors"
          @onChange="onChangeSelectedCsatVendors"
          :sidebarHeightApplied="true" />
      </div>
      <div class="each-dropdown">
        <MultiSelectorVue
          label="Data Source"
          placeholder="Select data source"
          :list="dataSourceList"
          :selected="selectedSourceList"
          @onChange="onChangeSource"
          :sidebarHeightApplied="true" />
      </div>
      <div class="each-dropdown month-picker">
        <MonthRangePicker :key="monthPickerKey" />
      </div>
      <div class="each-dropdown">
        <MultiSelectorVue
          label="Industry"
          placeholder="Select industry"
          :list="industries"
          :selected="selectedIndustries"
          @onChange="onChangeIndustry"
          :sidebarHeightApplied="true" />
      </div>
      <div class="each-dropdown">
        <MultiSelectorVue
          label="Business Size"
          placeholder="Select business size"
          :list="companies"
          :selected="selectedCompanies"
          @onChange="onChangeBusiness"
          :sidebarHeightApplied="true" />
      </div>
    </div>
  </side-panel-vue>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FilterHeader from '@/components/GlobalFilters/FilterHeader.vue'
import SingleSelectorVue from '../../Dropdowns/SingleSelector.vue'
import MultiSelectorVue from '../../Dropdowns/MultiSelector.vue'
import SidePanelVue from './SidePanel.vue'
import MonthRangePicker from '../../Dropdowns/MonthRangePicker.vue'
import { isEqual } from 'lodash'

/** This component contains the filters in the side panel */
export default {
  name: 'GlobalFilters',
  components: {
    FilterHeader,
    SingleSelectorVue,
    MultiSelectorVue,
    SidePanelVue,
    MonthRangePicker
  },
  data () {
    return {
      timePeriodList: [],
      toggle: false,
      monthPickerKey: 1,
      sidebarHeightApplied: true
    }
  },
  computed: {
    ...mapState('filters', [
      'categoryList',
      'selectedCategories',
      'ActiveVocTimePeriod',
      'ActiveVendorCsatTimePeriod',
      'productList',
      'selectedProducts',
      'dataSourceList',
      'selectedSourceList',
      'industries',
      'selectedIndustries',
      'companies',
      'selectedCompanies',
      'subscriptionRange',
      'subscriptionGranularity',
      'isInitialLoadDone',
      'subCategories',
      'csatVendorList',
      'selectedCsatVendors',
      'sortedCsatVendors'
    ]),
    ...mapState('user', ['userDetails']),
    ...mapState('common', ['active_page', 'isCollapsed']),
    ActiveTimePeriod () {
      if (this.active_page === 'vendor-csat') return this.ActiveVendorCsatTimePeriod
      return this.ActiveVocTimePeriod
    }
  },
  watch: {
    active_page (newValue, oldValue) {
      if (oldValue === 'vendor-csat') {
        if (this.subCategories.length && this.active_page !== 'vendor-csat') {
          this.getProductByCategoryId({
            categoryId: this.subCategories,
            subscriptionId: this.selectedCategories.subscriptionId
          })
          this.getSourceByCategoryId({
            subscriptionId: this.selectedCategories.subscriptionId,
            moduleId: this.userDetails.modules.find(
              (m) => m.module_name === 'voc'
            ).module_id
          })
          this.getTimeSubscriptionByCategoryId({
            subscriptionId: this.selectedCategories.subscriptionId,
            categoryId: this.subCategories
          })
        }
      }
    },
    subscriptionRange () {
      this.monthPickerKey += 1
    },
    selectedCsatVendors (newValue, oldValue) {
      if (!this.sortedCsatVendors.length) {
        this.updateSortedCsatVendors(newValue)
      } else {
        let currentList = [...this.sortedCsatVendors]
        let newVendors
        // If a vendor is being added to the list of selected vendors
        if (newValue.length > oldValue.length) {
          // Find out the new vendor(s)
          newVendors = newValue.filter(obj => {
            return !this.exists(obj, currentList)
          })
          // Add the new vendor to the current list of vendors. This will ensure that any open product columns are not lost
          for (const obj of newVendors) {
            currentList.push(obj)
          }

          // If a vendor is being removed from the list of selected vendors
        } else {
          // Find out which vendor was removed
          newVendors = oldValue.filter(obj => {
            return !this.exists(obj, newValue)
          })
          // Remove that vendor from the expandedVendors map. This is done so that the next time the vendor is selected, it does not show a 'hide column' symbol
          for (const obj of newVendors) {
            this.updateExpandedVendors({ name: obj.name, type: 'hide' })
          }
          // Remove that vendor and its products from the current list of vendors
          currentList = currentList.filter(obj => {
            return !newVendors.some(item => obj.name === item.name)
          })
        }
        // Update the lsit of sortedVendors using the current list
        this.updateSortedCsatVendors(currentList)
      }
    }
  },
  beforeDestroy () {
    this.resetSubCategories()
  },
  updated () {
    this.$nextTick(() => {
      if (
        this.subCategories.length &&
        this.selectedProducts.length &&
        this.selectedSourceList.length &&
        this.ActiveTimePeriod
      ) {
        this.updateIsInitialLoadDone(true)
      }
    })
  },
  mounted () {
    if (this.userDetails) {
      this.getDataForGlobalFilters(this.userDetails.userId)
    }
  },
  methods: {
    ...mapActions('filters', [
      'getDataForGlobalFilters',
      'updateSelectedCategory',
      'updateSelectedProducts',
      'updateSortedProducts',
      'getProductByCategoryId',
      'getCsatVendors',
      'updateSelectedSources',
      'updateSelectedIndustries',
      'updateSelectedCompanies',
      'updateSelectedCsatVendors',
      'updateSortedCsatVendors',
      'updateSelectedCountries',
      'getSourceByCategoryId',
      'getCumulativeSourceByClientId',
      'resetCategoryDependent',
      'getTimeSubscriptionByCategoryId',
      'getCumulativeTimeSubscriptionVoc',
      'updateIsInitialLoadDone',
      'updateSelectedNestedCategories',
      'resetSubCategories'
    ]),
    ...mapActions('csatdriver', ['updateExpandedVendors']),
    isEqual,
    /** Helper function to check if an object exists in some target array
     * @param item {Object} - The object that is being searched for in the list
     * @param target {Array} - The list/array of objects in which we search for the object *item*
     * @public
     */
    exists (item, target) {
      return target.some(obj => this.isEqual(obj, item))
    },
    /** This event handler updates the selected products
     * @public
     */
    onChangeProduct (data) {
      this.updateSortedProducts(data)
      this.updateSelectedProducts(data)
    },
    /** This event handler updates the selected products
     * @public
     */
    onChangeSelectedCsatVendors (data) {
      this.updateSelectedCsatVendors(data)
    },
    /** This event handler updates the selected category
     * @public
     */
    onChangeCategory (data) {
      this.resetCategoryDependent()
      this.updateSelectedCategory(data)
    },
    /** This event handler updates the selected sources
     * @public
     */
    onChangeSource (data) {
      this.updateSelectedSources({ sources: data, page: this.active_page })
    },
    /** This event handler updates the selected industries
     * @public
     */
    onChangeIndustry (data) {
      this.updateSelectedIndustries(data)
    },
    /** This event handler updates the selected company sizes
     * @public
     */
    onChangeBusiness (data) {
      this.updateSelectedCompanies(data)
    },
    /** This event handler updates the selected nested categories
     * @public
     */
    onChangeNestedData (data) {
      this.updateSelectedNestedCategories(data)
      if (data.length && this.active_page !== 'vendor-csat') {
        this.getProductByCategoryId({
          categoryId: data,
          subscriptionId: this.selectedCategories.subscriptionId
        })
        this.getSourceByCategoryId({
          subscriptionId: this.selectedCategories.subscriptionId,
          moduleId: this.userDetails.modules.find(
            (m) => m.module_name === 'voc'
          ).module_id
        })
        this.getTimeSubscriptionByCategoryId({
          subscriptionId: this.selectedCategories.subscriptionId,
          categoryId: data
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.side-panel-container {
  height: 100%;
  background: var(--sidebar-background-color);

  .product-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    img {
      width: 60px;
      object-fit: contain;
      height: 100%;
    }
  }
  .side-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .filter-container {
      overflow-y: auto;
      .each-dropdown {
        padding: 10px 20px;
        width: 225px;
        min-width: max-content; // This is to prevent displacement of filter text when the sidepanel is expanded
      }
    }
    .collapsed {
      // opacity: 0;
      display: none;
      // transition: 0.225s ease-out 0.075s;
    }
  }
}
</style>
