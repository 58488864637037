<template>
  <div class="list-selector">
    <b-input
      v-model="debouncedSearchText"
      class="search-input"
      :placeholder="placeholderText"
      type="search"
      icon="magnify">
    </b-input>
    <div class="list-items-main">
      <ul>
        <li>
          <label :class="['custom-checkbox', 'custom-checkbox-header']">
            <input
              type="checkbox"
              id="selectAll"
              v-model="isSelected"
              @click="selectAllData(!isSelected)" />
            <div class="custom-checkmark"></div>
            <p>{{ selectedDisplayText }}</p>
          </label>
        </li>
      </ul>
      <div class="list-items" :id="'infinite-list-items' + id">
        <ul v-for="(option, index) in filteredListItems" :key="index">
          <li>
            <label :class="['custom-checkbox']">
              <input
                type="checkbox"
                :id="option"
                :value="option"
                v-model="localSelected" />
              <div class="custom-checkmark"></div>
              <div class="line-block">
                <p>{{ option.name }}</p>
              </div>
            </label>
          </li>
        </ul>
        <div
          class="no-items-text loader-container"
          v-if="isInfiniteScroll"
          :class="['more-' + id, dualListLoader ? '' : 'loading-none']">
          <div class="loader"></div>
        </div>
        <div v-if="noItems" class="no-items-text">
          {{ noOptionsText }}
        </div>
        <div v-if="noFilteredItems && !dualListLoader" class="no-items-text">
          {{ noItemsFoundText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions, mapMutations, mapState } from 'vuex'

/**
 * This component is part of dual-selector transfer.
 */
export default {
  name: 'ListSelector',
  props: {
    listItems: {
      type: Array,
      default () {
        return []
      },
      required: true
    },
    selectedListItems: {
      type: Array,
      default () {
        return []
      }
    },
    removedListItems: {
      type: Array,
      default () {
        return []
      }
    },
    placeholderText: {
      type: String,
      default: 'Search'
    },
    noOptionsText: {
      type: String,
      default: 'No options'
    },
    noItemsFoundText: {
      type: String,
      default: 'No options found'
    },
    id: {
      type: String,
      required: true
    },
    isInfiniteScroll: {
      type: Boolean,
      default: false
    },
    isVisible: {
      type: Number,
      default: null
    },
    type: String
  },
  data () {
    return {
      searchText: '',
      isSelected: false,
      localSelected: [],
      observer: null
      // isTyped: false
    }
  },
  computed: {
    ...mapState('esgControversies', {
      infiniteScrollESG: 'infiniteScroll',
      dualListLoaderESG: 'dualListLoader',
      moreSearchData: 'moreSearchData'
    }),
    ...mapState('reportsRepoFilters', {
      infiniteScrollReportsRepo: 'infiniteScroll',
      dualListLoaderReportsRepo: 'dualListLoader',
      moreSearchData: 'moreSearchData'
    }),
    ...mapState('common', ['currentModule']),
    // eslint-disable-next-line vue/return-in-computed-property
    infiniteScroll () {
      if (this.currentModule === 'esg-controversies') {
        return this.infiniteScrollESG
      } else if (this.currentModule === 'reports-repository') {
        return this.infiniteScrollReportsRepo
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    dualListLoader () {
      if (this.currentModule === 'esg-controversies') {
        return this.dualListLoaderESG
      } else if (this.currentModule === 'reports-repository') {
        return this.dualListLoaderReportsRepo
      }
    },
    selectedDisplayText () {
      if (
        this.type === 'company' ||
        this.type === 'equityReports' ||
        this.type === 'fixedIncome'
      ) {
        if (this.localSelected.length !== 1) {
          return `${this.localSelected.length} Companies Selected`
        } else {
          return `${this.localSelected.length} Company Selected`
        }
      } else if (this.type === 'publisher') {
        if (this.localSelected.length !== 1) {
          return `${this.localSelected.length} Publishers Selected`
        } else {
          return `${this.localSelected.length} Publisher Selected`
        }
      }
      return ''
    },
    noItems () {
      return (
        (this.selectedListItems.length && this.availableItems.length < 1) ||
        !this.listItems ||
        this.listItems.length < 1
      )
    },
    noFilteredItems () {
      return (
        this.availableItems &&
        this.availableItems.length > 0 &&
        (!this.filteredListItems || this.filteredListItems.length < 1)
      )
    },
    debouncedSearchText: {
      get () {
        return this.searchText
      },
      set: debounce(function setNewValue (newValue) {
        this.searchText = newValue
      }, 500)
    },
    availableItems () {
      return [...this.listItems]
    },
    filteredListItems () {
      let filteredItems = []
      if (this.searchText != null && this.searchText !== '') {
        filteredItems = this.availableItems.filter((item) => {
          return item.name.toLowerCase().includes(this.searchText.toLowerCase())
        })
        return filteredItems
      }
      return this.availableItems
    }
  },
  watch: {
    selectedListItems () {
      this.localSelected = []
      this.isSelected = false
    },
    removedListItems () {
      this.localSelected = []
      this.isSelected = false
    },
    localSelected () {
      if (
        (this.localSelected.length > 0 &&
          this.localSelected.length === this.listItems.length) ||
        (this.localSelected.length > 0 &&
          this.filteredListItems.length > 0 &&
          this.localSelected.length === this.filteredListItems.length)
      ) {
        this.isSelected = true
      } else {
        this.isSelected = false
      }
      const selectedOptions = [...this.selectedListItems, ...this.localSelected]
      this.$emit('onChangeOptions', selectedOptions)
    },
    searchText (newSearchText) {
      this.localSelected = [] // set empty array first before using search input and after it is cleared
      if (this.id.includes('__left_side')) {
        this.updateDualListLoader(true)
        this.updateInfiniteScroll(true)
        if (newSearchText) {
          this.updateSearchPublisherOffset(0)
          this.updateSearchCompanyOffset(0)
          this.getData()
        }
      }
    },
    isVisible (isVisible) {
      if (!isVisible) {
        this.searchText = ''
      }
    },
    listItems (newItems, oldItems) {
      this.localSelected = [] // reset to empty whenever items added or removed in listItems
      if (newItems.length === 0) {
        this.isSelected = false
      }
      // it will fire untill we get the new data from the backend
      if (newItems.length === oldItems.length) {
        if (this.type === 'company') {
          this.getEsgCompanyList(this.searchText)
        } else if (this.type === 'publisher') {
          this.getNewsPublishers(this.searchText)
        } else if (this.type === 'equityReports') {
          this.getReportsRepoCompanyList(this.searchText)
        } else if (this.type === 'fixedIncome') {
          this.getReportsRepoCompanyList(this.searchText)
        }
      }
    }
  },
  mounted () {
    if (this.isInfiniteScroll && this.currentModule) {
      this.updateInfiniteScroll(true)
      this.infiniteScrollList()
    }
  },
  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    ...mapActions('esgControversies', {
      getNewsPublishers: 'getNewsPublishers',
      getEsgCompanyList: 'getEsgCompanyList',
      updateSearchPublisherOffset: 'updateSearchPublisherOffset',
      updateESGDualListLoader: 'updateDualListLoader',
      updateESGSearchCompanyOffset: 'updateSearchCompanyOffset'
    }),
    ...mapActions('reportsRepoFilters', {
      getReportsRepoCompanyList: 'getReportsRepoCompanyList',
      updateReportsRepoDualListLoader: 'updateDualListLoader',
      updateReportsRepoSearchCompanyOffset: 'updateSearchCompanyOffset',
      getFixedIncomeCompanyList: 'getFixedIncomeCompanyList'
    }),
    ...mapMutations('esgControversies', {
      updateESGInfiniteScroll: 'SET_INFINITE_SCROLL'
    }),
    ...mapMutations('reportsRepoFilters', {
      updateReportsRepoInfiniteScroll: 'SET_INFINITE_SCROLL'
    }),
    updateSearchCompanyOffset: function (offset) {
      if (this.currentModule === 'esg-controversies') {
        this.updateESGSearchCompanyOffset(offset)
      } else if (this.currentModule === 'reports-repository') {
        this.updateReportsRepoSearchCompanyOffset(offset)
      }
    },
    updateInfiniteScroll: function (bool) {
      if (this.currentModule === 'esg-controversies') {
        this.updateESGInfiniteScroll(bool)
      } else if (this.currentModule === 'reports-repository') {
        this.updateReportsRepoInfiniteScroll(bool)
      }
    },
    updateDualListLoader: function (bool) {
      if (this.currentModule === 'esg-controversies') {
        this.updateESGDualListLoader(bool)
      } else if (this.currentModule === 'reports-repository') {
        this.updateReportsRepoDualListLoader(bool)
      }
    },
    getData: debounce(function callAPI () {
      if (this.type === 'company') {
        this.getEsgCompanyList(this.searchText)
      } else if (this.type === 'publisher') {
        this.getNewsPublishers(this.searchText)
      } else if (this.type === 'equityReports') {
        this.getReportsRepoCompanyList(this.searchText)
      } else if (this.type === 'fixedIncome') {
        this.getReportsRepoCompanyList(this.searchText)
      }
    }, 100),
    selectAllData (isSelected) {
      if (!isSelected) {
        this.localSelected = []
      } else {
        this.localSelected = this.filteredListItems
      }
    },
    infiniteScrollList () {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            this.infiniteScroll &&
            this.dualListLoader
          ) {
            this.getData()
            this.updateInfiniteScroll(false)
          }
        })
      })
      this.observer.observe(document.querySelector(`.more-${this.id}`))
    }
  }
}
</script>

<style lang="scss">
.list-selector {
  width: -webkit-fill-available;
  width: -moz-available;
  height: 100%;
  padding: 17px;
  border: 1px solid var(--dual-selector-list-border-color);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;

  .search-input {
    border: 1px solid var(--dual-selector-search-border);

    .input {
      border-radius: unset;
      background: transparent;
      border: none;
      height: 34px;
      color: var(--dual-selector-search-text);
      &::placeholder {
        color: var(--dual-selector-search-placeholder);
      }
    }

    .icon {
      color: #f57777 !important;
      height: 34px;
    }
  }

  .list-items-main {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    .list-items {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      width: 100%;
      overflow: scroll;
    }
  }
}

.no-items-text {
  color: var(--dual-selector-list-option);
}

.custom-checkbox {
  color: var(--dual-selector-list-option);
  margin: 0px;
  font-size: 16px;
  padding: 5px 10px 5px 0;
  font-family: Quicksand;
  width: 100%;

  p {
    display: flex;
    flex: 1;
    padding-left: 5px;
  }
}

.custom-checkbox-header {
  font-size: 14px;
  color: var(--dual-selector-list-option);
  margin-bottom: 6px;
  margin-top: 6px;
}

.custom-checkmark {
  position: relative;
  height: 13px;
  border-radius: 2px;
  width: 13px;
  min-width: 13px;
  background-color: var(--dual-selector-checkbox-background);
  border: 2px solid var(--dual-selector-checkbox-border);
}

.custom-checkbox:hover input ~ .custom-checkmark {
  background-color: var(--dual-selector-checkbox-background__hover);
}

.custom-checkbox input:checked ~ .custom-checkmark {
  border: 2px solid var(--dual-selector-checkbox-border__checked);
}

.custom-checkmark:after {
  content: '';
  left: 2px;
  position: absolute;
  display: block;
}

.custom-checkbox .custom-checkmark:after {
  width: 5px;
  height: 9px;
  border: solid var(--dual-selector-checkmark);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-checkbox input:checked ~ .custom-checkmark:after {
  border: solid var(--dual-selector-checkmark__checked);
  border-width: 0 2px 2px 0;
}

.loader-container {
  display: flex;
  justify-content: center;
  padding: 5px 0;
}

.loading-none {
  display: none;
}
</style>
