<template>
  <div class="extendible-multi-selector">
    <div v-if="label.length" class="top-label">
      {{ label }}
    </div>
    <ul class="multi-selector-menu">
      <li class="multi-selector-option">
        <label class="custom-checkbox">
          <input type="checkbox" name="" id="outermenu-select-all" @click="handleSelectAll($event)">
          <div class="checkmark"></div>
          <p>All</p>
        </label>
      </li>
      <li class="multi-selector-option" v-for="(object, index) in outerMenuList" :key="index">
        <label class="custom-checkbox" :class="object.isFullySelected !== undefined && !object.isFullySelected && 'partial-selection'" @mouseover="toggleExtension(index + 1, 'in')" @mouseout="toggleExtension(index + 1, 'out')">
          <input type="checkbox" v-model="outerMenuSelected" :id="index + 1" :value="object.isFullySelected && object" @click="handleOuterMenuSelection($event, object)">
          <div class="checkmark"></div>
          <p>{{ object.region_name }}</p>
          <img src="../../assets/Arrow-down.svg" alt="right-arrow" style="display: flex; margin-left: auto; transform: rotate(-90deg)">
        </label>
        <!-- The extended menu class does not work if you use v-if instead of v-show. Do NOT change this -->
        <div :class="['extended-menu','extended-menu-' + (index + 1)]" v-show="showExtension === (index + 1)" @mouseover="handleDisplayOfEM(index + 1, 'in')" @mouseleave="handleDisplayOfEM(index + 1, 'out')">
          <div class="search-wrapper">
            <input type="text" name="" id="" placeholder="Search Countries" class="search-input" v-model="searchText" autocomplete="new-password">
          </div>
          <common-selection name="country_name" :open="true" :list="object.children" :selected="getSelectedInnerMenuOptions(index)" :searchText="searchText" :type="'extendible-multi-selector'" @focus="updateSelectedInnerMenuOptions" />
        </div>
      </li>
      </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { isEqual } from 'lodash'
import { analyticsEvents } from '../../constant/data'
import CommonSelection from './CommonSelection.vue'
export default {
  components: { CommonSelection },
  props: {
    /** This prop contains the name of the filter. It shows up above the options */
    label: String,
    /** This prop contains the list of selected options (at the lowest/inner most level) */
    selected: Array,
    list: Array
  },
  data () {
    return {
      showExtension: null,
      searchText: '',
      outerMenuSelected: [],
      outerMenuList: this.list,
      localInnerMenuSelected: this.selected
    }
  },
  computed: {
    ...mapState('filters', ['regionList', 'selectedRegions']),
    ...mapState('user', ['userDetails']),
    // Region filter specific variable
    regionScenario () {
      if (this.outerMenuSelected.length === 1) return 0
      let full = 0
      let partial = 0
      for (const obj of this.outerMenuSelected) {
        if (obj.isFullySelected === true) full = 1
        else partial = 2
      }
      return full + partial
    }
  },
  watch: {
    list () {
      if (this.list.length) {
        this.outerMenuList = this.list
      }
    },
    selected () {
      if (this.selected.length) {
        this.localInnerMenuSelected = this.selected
      }
    },
    localInnerMenuSelected (newValue, oldValue) {
      if (newValue.length) {
        let totalInnerOptions = 0
        for (const obj of this.outerMenuList) {
          totalInnerOptions += obj.children.length
        }
        if (newValue.length === totalInnerOptions) {
          document.querySelector('#outermenu-select-all').checked = true
        } else {
          document.querySelector('#outermenu-select-all').checked = false
        }
      }
      this.getSelectedOuterMenuOptions()
      if (this.outerMenuList.length) {
        this.$emit('onChange', this.localInnerMenuSelected, this.outerMenuSelected)
        this.updateSelectedRegions(this.outerMenuSelected)
      }
    },
    outerMenuSelected (newValue, oldValue) {
      this.updateSelectedRegions(newValue)
    },
    regionScenario (newVal, oldVal) {
      this.updateRegionView(newVal)
    }
  },
  mounted () {
    this.getSelectedOuterMenuOptions()
    this.updateRegionView(this.regionScenario)
  },
  methods: {
    ...mapActions('tam', ['updateRegionView']),
    ...mapActions('filters', ['updateSelectedRegions']),
    ...mapActions('analytics', ['updateEvent']),
    isEqual,
    /** This method either selects all inner and outer options or it deselects them.
     * @param event - This parameter allows us to check if the 'Select All' option in the outer menu has been selected or not
     * @public
     */
    handleSelectAll (event) {
      if (event.target.checked) {
        this.updateAnalytics()
        this.outerMenuSelected = this.outerMenuList
        this.localInnerMenuSelected = []
        for (const obj of this.outerMenuList) {
          for (const child of obj.children) {
            this.localInnerMenuSelected.push(child)
          }
        }
      } else {
        this.outerMenuSelected = []
        this.localInnerMenuSelected = []
      }
    },
    /** Helper function to check if an object exists in some target array
     * @param item {Object} - The object that is being searched for in the list
     * @param target {Array} - The list/array of objects in which we search for the object *item*
     * @public
     */
    exists (item, target) {
      return target.some(obj => this.isEqual(obj, item))
    },
    /** This method hides and displays the extended menu for each option
     * @param index {Number} - The index of the option for which the extended menu needs to be shown
     * @param type {String} - A value which denotes whether the extended menu is to be displayed or hidden
     * @public
     */
    toggleExtension (index, type) {
      const elem = document.querySelectorAll('.multi-selector-option')[index]
      const extendedMenu = document.querySelector(`.extended-menu-${index}`)
      if (type === 'in') {
        this.showExtension = index
        // elem.style.backgroundColor = '#354052'
        elem.style.backgroundColor = 'var(--sidebar-option-background-color)'
        extendedMenu.style.top = elem.getBoundingClientRect().top + 'px'
        extendedMenu.style.left = elem.getBoundingClientRect().left + elem.getBoundingClientRect().width + 'px'
        document.querySelector('#app').appendChild(extendedMenu)
      } else {
        elem.style.backgroundColor = 'transparent'
        this.showExtension = null
      }
    },
    /** This method keeps the extended menu from hiding itself once the mouse has crossed over from the option into the actual extended menu. It also causes the main menu option styling to remain
     * @param index {Number} - The index of the option for which the extended menu needs to be shown
     * @param type {String} - A value which denotes whether the extended menu is to be displayed or hidden
     * @public
     */
    handleDisplayOfEM (index, type) {
      const elem = document.querySelectorAll('.multi-selector-option')[index]
      if (type === 'in') {
        this.showExtension = index
        // elem.style.backgroundColor = '#354052'
        elem.style.backgroundColor = 'var(--sidebar-option-background-color)'
      } else {
        elem.style.backgroundColor = 'transparent'
        this.showExtension = null
      }
    },
    /** This method decides the behavior when an option in the outer menu is clicked on. If an option is selected, all its children are selected as well, and if it is deselected, all its children are deselected as well
     * @param event - Event object which lets us know which option was clicked on
     * @param region - The data/value that the option contains
     * @public
     */
    handleOuterMenuSelection (event, region) {
      this.updateAnalytics()
      // If the region option is directly clicked on, and it is selected
      if (event.target.checked) {
        region.children.forEach(child => {
          // Add each country from the region to the list of selected countries, if it does not already exist there
          if (!this.exists(child, this.localInnerMenuSelected)) {
            this.localInnerMenuSelected.push(child)
          }
        })
        // If the region option is directly clicked on, and it is deselected
      } else {
        // Remove all the children of this region from the list of selected countries
        region.children.forEach(child => {
          this.localInnerMenuSelected = this.localInnerMenuSelected.filter(obj => !this.isEqual(obj, child))
        })
      }
    },
    /** This method matches the list of selected inner options with the list of inner options belonging to each outer option. If there is even a single match, that outer option is added to the list of selected outer options. If an outer option's children do not match a single selected inner option, then that outer option is removed from the list of selected outer options if it was present there previously
     * @public
     */
    getSelectedOuterMenuOptions () {
      for (const obj of this.outerMenuList) {
        // Check if any countries from this region are selected
        const countryList = obj.children.filter(item => {
          return this.localInnerMenuSelected.some(el => {
            return this.isEqual(el, item)
          })
        })
        if (countryList.length > 0) {
          // If there are countries present, then check if all the countries are selected, or only a few
          if (countryList.length === obj.children.length) {
            obj.isFullySelected = true
          } else if (countryList.length < obj.children.length) {
            obj.isFullySelected = false
          }
          // Only push the region to the outerMenuSelected array if it does not already exist there. We're not using the exists() helper function because that was giving some unexpected values under the hood.
          let regionFound = false
          for (let i = 0; i < this.outerMenuSelected.length; i++) {
            if (this.outerMenuSelected[i].region_id === obj.region_id) {
              // this.outerMenuSelected[i] = obj
              this.$set(this.outerMenuSelected, i, obj)
              regionFound = true
              break
            }
          }
          if (!regionFound) {
            this.outerMenuSelected.push(obj)
          }
        } else {
          // Get rid of the isFullySelected variable to let the css unselect the checkbox.
          delete obj.isFullySelected // -> This is an unused command. Might use it later
          // If no country is selected, then the region itself is not selected/deselected
          this.outerMenuSelected = this.outerMenuSelected.filter(el => {
            return !this.isEqual(el, obj)
          })
        }
      }
    },
    /** This method marks all the inner options that are selected in an extended menu
     * @param index {Number} - The index of the outer option whose inner options are to be inspected/checked
     */
    getSelectedInnerMenuOptions (index) {
      const regionalCountries = this.outerMenuList[index].children
      const selected = this.localInnerMenuSelected.filter((el) => regionalCountries.some((obj) => this.isEqual(obj, el)))
      return selected
    },
    /** This method updates the list of selected inner options.
     * @param payload {Object, Array} - The inner options that were selected (and are emitted from the common-selection component)
     * @param list {Array} - The total list of options from which *payload* is selected
     * @public
     */
    // This works for v-on:focus. Using this function with v-on:onChange is NOT recommended because that event was getting triggered too often, and it was causing weird behavior
    updateSelectedInnerMenuOptions (payload, list) {
      if (payload) {
        this.updateAnalytics()
        // If payload has length, it means 'Select All' was clicked on. A length of 0 means it was deselected while a length > 0 means it was selected
        if (payload.length) {
          // Add all elements in the payload/sublist to the localInnerMenuSelected list
          payload.forEach(element => {
            if (!this.exists(element, this.localInnerMenuSelected)) {
              this.localInnerMenuSelected.push(element)
            }
          })
        } else {
          if (payload.length === 0) {
            // Remove all items present in the sublist from the localInnerMenuSelected list
            list.forEach(element => {
              this.localInnerMenuSelected = this.localInnerMenuSelected.filter(obj => !this.isEqual(obj, element))
            })
          } else {
            // If payload does not have length, it means an individual option was clicked
            // If the individual country does not exist, add it, otherwise remove it.
            if (!this.exists(payload, this.localInnerMenuSelected)) {
              this.localInnerMenuSelected.push(payload)
            } else {
              this.localInnerMenuSelected = this.localInnerMenuSelected.filter(obj => !this.isEqual(obj, payload))
            }
          }
        }
      }
    },
    updateAnalytics () {
      this.updateEvent({
        userId: this.userDetails.userId,
        event: 'click',
        label: analyticsEvents.region,
        pageUrl: window.location.href
      })
    }
  }
}
</script>

<style lang="scss" src="./common.scss" scoped />
