<template>
    <div class="data-source-container month-range-picker">
      <div class="top-label">Time period</div>
      <date-picker
        input-class="mx-input"
        ref="timePeriodPicker"
        :shortcuts="shortcuts"
        v-model="date"
        lang="en"
        type="day"
        value-type="format"
        format="YYYY-MM-DD"
        :range="isRange"
        :editable="isEditable"
        :clearable="isClearable"
        :disabled-date="disabledOutsideSubs"
        @open="prepareYearAttr"
        @close="handleRangeClose"
        @change="updateTimePeriod">
        <template v-slot:header>{{ date[0] | dateFormatter }} ~ {{ date[1] | dateFormatter }}
        </template>
        <template v-slot:input :slot-scope="picker">
          <div v-if="customRangevalue" class="mx-input">
            {{ customRangevalue }}
          </div>
          <div v-else class="mx-input">
            {{ date[0] | dateFormatter }} ~ {{ date[1] | dateFormatter }}
          </div>
        </template>
        <template v-slot:footer="{ emit }" v-if="false">
          <div
            v-if="calanderOpened"
            class="period-selector">
            <div class="head">Compare to</div>
            <b-select
              placeholder="Select a name"
              size="is-small"
              v-model="compareType">
              <option
                v-for="option in options"
                :value="option.value"
                :key="option.value">
                {{ option.name }}
              </option>
            </b-select>
            <div class="from-input">
              <div>From</div>
              <b-field grouped>
                <b-select
                  placeholder="MMM"
                  class="datepart1"
                  v-model.number="fromMonth"
                  :disabled="compareType === 0"
                  @input="handleFromChange"
                  size="is-small">
                  <option
                    v-for="month in months"
                    :value="month.value"
                    :key="month.label">
                    {{ month.label }}
                  </option>
                </b-select>
                <b-select
                  placeholder="YYYY"
                  class="datepart3"
                  v-model.number="fromYear"
                  :disabled="compareType === 0"
                  @input="handleFromChange"
                  size="is-small">
                  <option
                    v-for="year in subscribedYears"
                    :value="year"
                    :key="year">
                    {{ year }}
                  </option>
                </b-select>
                <div class="errorMessage from-error">
                  {{ compareFromErrorMessage }}
                </div>
              </b-field>
            </div>
            <div class="from-input">
              <div>To</div>
              <b-field grouped>
                <b-select
                  placeholder="MMM"
                  class="datepart1"
                  v-model.number="toMonth"
                  :disabled="compareType === 0"
                  @input="handleToChange"
                  size="is-small">
                  <option
                    v-for="month in months"
                    :value="month.value"
                    :key="month.label">
                    {{ month.label }}
                  </option>
                </b-select>
                <b-select
                  placeholder="YYYY"
                  class="datepart3"
                  v-model.number="toYear"
                  :disabled="compareType === 0"
                  @input="handleToChange"
                  size="is-small">
                  <option
                    v-for="year in subscribedYears"
                    :value="year"
                    :key="year">
                    {{ year }}
                  </option>
                </b-select>
                <div class="errorMessage to-error">
                  {{ compareToErrorMessage }}
                </div>
              </b-field>
            </div>
            <div>
              <div class="errorMessage compare-error">
                {{ compareErrorMessage }}
              </div>
            </div>
          </div>
        </template>
      </date-picker>
    </div>
  </template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mapState, mapActions } from 'vuex'
import { getEsgCustomDateRange } from '@/util/util'
import moment from 'moment'
import * as d3 from 'd3'
import { analyticsEvents, calendarOptions, monthList } from '@/constant/data'

export default {
  name: 'TimePeriodPicker',
  components: {
    DatePicker
  },
  filters: {
    dateFormatter (val) {
      return val ? moment(val, 'YYYY-MM-DD').format('DD-MMM-YY') : ''
    }
  },
  data () {
    return {
      date: [],
      isClearable: false,
      isRange: true,
      isEditable: false,
      selectedButton: null,
      calendarOptions,
      customRangevalue: '',
      calanderOpened: false,
      compareFrom: '',
      compareTo: '',
      options: [
        { name: 'Previous period', value: 0 },
        { name: 'Custom period', value: 1 }
      ],
      compareType: 0,
      fromMonth: null,
      fromYear: null,
      toMonth: null,
      toYear: null,
      compareFromDate: null,
      compareToDate: null,
      compareErrorMessage: null,
      compareFromErrorMessage: null,
      compareToErrorMessage: null,
      defaultPreviousPeriod: [],
      months: monthList
    }
  },
  computed: {
    ...mapState('filters', [
      'isInitialLoadDone'
    ]),
    ...mapState('user', ['userDetails']),
    ...mapState('common', ['currentModule']),
    ...mapState('esgControversies', ['activeEsgTimePeriod']),
    ...mapState('reportsRepoFilters', ['activeReportsRepoTimePeriod']),

    ActiveTimePeriod () {
      if (this.currentModule === 'esg-controversies') {
        return this.activeEsgTimePeriod
      } else if (this.currentModule === 'reports-repository') {
        return this.activeReportsRepoTimePeriod
      } else {
        return null
      }
    },
    currentPath () {
      return this.$route.name
    },
    shortcuts () {
      const options = []
      options.push({
        text: 'Today',
        onClick: () => {
          this.updateRange('Today')
        }
      })
      options.push({
        text: 'Current week',
        onClick: () => {
          this.updateRange('currentWeek')
        }
      })
      options.push({
        text: 'Previous week',
        onClick: () => {
          this.updateRange('previousWeek')
        }
      })
      options.push({
        text: 'Current month',
        onClick: () => {
          this.updateRange('currentMonth')
        }
      })
      options.push({
        text: 'Previous month',
        onClick: () => {
          this.updateRange('previousMonth')
        }
      })
      options.push({
        text: 'Current year',
        onClick: () => {
          this.updateRange('currentYear')
        }
      })
      options.push({
        text: 'Last 12 months',
        onClick: () => {
          this.updateRange('lastNMonths', 11)
        }
      })

      return options
    }
  },
  watch: {
    ActiveTimePeriod () {
      this.checkCustomRange()
      if (this.date !== this.ActiveTimePeriod.range) {
        this.date = this.ActiveTimePeriod.range
      }
    }
  },
  mounted () {
    // updating selected sentiment on mount
    let timePeriod
    if (this.currentModule === 'esg-controversies') {
      timePeriod = JSON.parse(localStorage.getItem('ESG_TIME_PERIOD'))
    } else if (this.currentModule === 'reports-repository') {
      timePeriod = JSON.parse(localStorage.getItem('REPORTS_REPO_TIME_PERIOD'))
    }
    if (timePeriod) {
      if (this.currentModule === 'esg-controversies') {
        this.updateActiveEsgTimePeriod(timePeriod)
      } else if (this.currentModule === 'reports-repository') {
        this.updateActiveReportsRepoTimePeriod(timePeriod)
      }
    } else {
      if (this.currentModule === 'esg-controversies') {
        this.date = [
          moment().startOf('month').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ]
        this.customRangevalue = 'Current month'
      } else if (this.currentModule === 'reports-repository') {
        this.date = [
          moment().subtract(11, 'month').startOf('month').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ]
        this.customRangevalue = 'Last 12 months'
      }
      this.updateTimePeriod()
      this.makeButtonActive()
    }
    this.checkCustomRange()
  },
  methods: {
    ...mapActions('analytics', ['updateEvent']),
    ...mapActions('esgControversies', ['updateActiveEsgTimePeriod']),
    ...mapActions('reportsRepoFilters', ['updateActiveReportsRepoTimePeriod']),
    checkCustomRange () {
      if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().format('YYYY-MMM-DD')
      ) {
        this.customRangevalue = 'Today'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().startOf('isoWeek').format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().format('YYYY-MMM-DD')
      ) {
        this.customRangevalue = 'Current week'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().subtract(1, 'isoWeek').startOf('isoWeek').format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().subtract(1, 'isoWeek').endOf('isoWeek').format('YYYY-MMM-DD')
      ) {
        this.customRangevalue = 'Previous week'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().startOf('month').format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().format('YYYY-MMM-DD')
      ) {
        this.customRangevalue = 'Current month'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().subtract(1, 'month').startOf('month').format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().subtract(1, 'month').endOf('month').format('YYYY-MMM-DD')
      ) {
        this.customRangevalue = 'Previous month'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().subtract(11, 'month').startOf('month').format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().format('YYYY-MMM-DD')
      ) {
        this.customRangevalue = 'Last 12 months'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().subtract(1, 'year').set('month', 0).format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().subtract(1, 'year').set('month', 11).format('YYYY-MMM-DD')
      ) {
        this.customRangevalue = 'Previous year'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().startOf('year').format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().format('YYYY-MMM-DD')
      ) {
        this.customRangevalue = 'Current year'
      } else {
        this.customRangevalue = ''
      }
    },
    addAttributeToShortcut () {
      document
        .querySelectorAll('.mx-datepicker-main .mx-datepicker-sidebar button')
        .forEach((btn) => {
          const dataText = btn.innerText
          if (dataText === 'Previous week') {
            btn.classList.add('hrule')
          }
        })
    },
    disabledOutsideSubs (date) {
      const today = moment().format('YYYY-MM-DD')
      const tempDate = moment(date).format('YYYY-MM-DD')
      if (this.currentModule === 'esg-controversies') {
        const todayMinusOneYear = moment().subtract(365, 'days').format('YYYY-MM-DD')
        return (
          tempDate > today ||
          tempDate < todayMinusOneYear
        )
      } else if (this.currentModule === 'reports-repository') {
        const todayMinusThreeYears = moment().subtract(3, 'years').format('YYYY-MM-DD')
        return (
          tempDate > today ||
          tempDate < todayMinusThreeYears
        )
      }
    },
    getActiveTimeObj (range) {
      const timeObject = {}
      timeObject.range = range
      return timeObject
    },
    // update the selected time period
    updateTimePeriod () {
      const timeObject = {}
      timeObject.range = this.date
      if (this.currentModule === 'esg-controversies') {
        this.updateActiveEsgTimePeriod(timeObject)
      } else if (this.currentModule === 'reports-repository') {
        this.updateActiveReportsRepoTimePeriod(timeObject)
      }
    },
    updateRange (type, months, range, granularity) {
      this.date = getEsgCustomDateRange(type, months, range)
      const timeObj = this.getActiveTimeObj(this.date)
      if (this.currentModule === 'esg-controversies') {
        this.updateActiveEsgTimePeriod(timeObj)
      } else if (this.currentModule === 'reports-repository') {
        this.updateActiveReportsRepoTimePeriod(timeObj)
      }
      this.makeButtonActive()
    },
    makeButtonActive () {
      if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().format('YYYY-MMM-DD')
      ) {
        this.selectedButton = 'today'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().startOf('isoWeek').format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().format('YYYY-MMM-DD')
      ) {
        this.selectedButton = 'currentWeek'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().subtract(1, 'isoWeek').startOf('isoWeek').format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().subtract(1, 'isoWeek').endOf('isoWeek').format('YYYY-MMM-DD')
      ) {
        this.selectedButton = 'previousWeek'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().startOf('month').format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().format('YYYY-MMM-DD')
      ) {
        this.selectedButton = 'currentMonth'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().subtract(1, 'month').startOf('month').format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().subtract(1, 'month').endOf('month').format('YYYY-MMM-DD')
      ) {
        this.selectedButton = 'previousMonth'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().subtract(11, 'month').startOf('month').format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().format('YYYY-MMM-DD')
      ) {
        this.selectedButton = 'last12Months'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().subtract(1, 'year').set('month', 0).format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().subtract(1, 'year').set('month', 11).format('YYYY-MMM-DD')
      ) {
        this.selectedButton = 'previousYear'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM-DD') ===
        moment().startOf('year').format('YYYY-MMM-DD') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM-DD') ===
        moment().format('YYYY-MMM-DD')
      ) {
        this.selectedButton = 'currentYear'
      } else {
        this.selectedButton = ''
      }

      const check = this.calendarOptions.filter(
        ({ value }) => value === this.selectedButton
      )
      if (this.$refs.timePeriodPicker.$refs.popup.$el.firstElementChild) {
        for (const button of this.$refs.timePeriodPicker.$refs.popup.$el.firstElementChild.querySelectorAll(
          'button'
        )) {
          if (check.length && button.innerHTML === check[0].key) {
            button.classList.add('active')
          } else {
            button.classList.remove('active')
          }
        }
      }
      this.selectedButton = ''
    },
    handleRangeClose () {
      this.calanderOpened = false
    },
    prepareYearAttr () {
      this.calanderOpened = true
      setTimeout(() => {
        this.addAttributeToShortcut()
      }, 5)
      setTimeout(() => {
        const cells = document.getElementsByClassName('cell')
        for (let i = 0; i < cells.length; i++) {
          const item2 = cells[i]
          const text = d3
            .select(item2.closest('.mx-calendar-panel-date'))
            .select('.mx-calendar-header .mx-calendar-header-label')
            .text()
          item2.setAttribute('year-text', text.trim())
        }
        this.makeButtonActive()
      }, 10)
      this.updateEvent({
        userId: this.userDetails.userId,
        event: 'click',
        label: analyticsEvents['Time Period'],
        pageUrl: window.location.href
      })
    }
  }
}
</script>

<style lang="scss">
.mx-calendar{
  padding: 6px 12px 14px 12px;
}

.mx-calendar-content table th {
    color:white
  }

.mx-table-date .cell.not-current-month {
    color: #8f8f8f;
}

</style>
<style lang="scss" src="./common.scss" scoped />
