<template>
  <div
    :class="`${
      sidebarHeightApplied
        ? 'data-source-container-sidebar'
        : 'data-source-container'
    }`">
    <div v-if="label.length" class="top-label">
      {{ label }}
    </div>
    <dropdown-wrap-vue @onClickOutside="onClickOutside">
      <div class="dropdown-wrap" :ref="`dualSelectorFilter-${id}`">
        <b-button
          :class="[
            'data-source-header',
            label.length && open ? 'active' : '',
            !label.length ? 'local-button-background' : ''
          ]"
          @click="openTab(id)">
          <div class="button-container">
            <div :class="['ellipsis', !label.length ? 'active-color' : '']">
              {{ dualSelectorLabel }}
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#a8aab7" d="M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"/></svg>
          </div>
        </b-button>
      </div>
      <div
        v-show="open === id"
        class="dual-selector-wrapper"
        :id="`dual-selector-wrapper-${id}`">
        <div class="left-arrow"></div>
        <div class="dual-selector-inner-wrapper">
          <div class="header">
            <p class="label">Select {{ label }}</p>
            <div class="close-button-wrapper" @click="onClickOutside">
              <b-icon icon="close"></b-icon>
            </div>
          </div>
          <div class="dual-selector">
            <ListSelector
              :id="'list_selector_'+ id + '__left_side'"
              :list-items="availableOptions"
              :no-options-text="noOptionsText"
              :no-items-found-text="`No ${label} found`"
              :selected-list-items="selectedItems"
              :placeholder-text="`Search ${label} name`"
              :isInfiniteScroll="true"
              :type="type"
              :isVisible="open"
              @onChangeOptions="onChangeSelectedOptions" />
            <div class="select-actions">
              <div class="primary-btns">
                <button
                  class="btn"
                  :class="isAddActive ? '' : 'btn-disabled'"
                  @click="onSelectOptions">
                  <span> Add &gt&gt </span>
                </button>
                <button
                  class="btn"
                  :class="isRemoveActive ? '' : 'btn-disabled'"
                  @click="onUnselectOptions">
                  <span> &lt&lt Remove </span>
                </button>
              </div>
              <div>
                <button class="btn ok-btn" @click="onClickOutside">Ok</button>
              </div>
            </div>
            <ListSelector
              :id="'list_selector_'+ id + '__right_side'"
              :list-items="selectedItems"
              :no-options-text="`No ${label} selected`"
              :no-items-found-text="`No ${label} found`"
              :removed-list-items="removedItems"
              :placeholder-text="`Search ${label} name`"
              :type="type"
              :isVisible="open"
              @onChangeOptions="onChangeRemovedOptions" />
          </div>
        </div>
      </div>
    </dropdown-wrap-vue>
  </div>
</template>

<script>
import ListSelector from './ListSelector.vue'
import DropdownWrapVue from '../Common/DropdownWrap.vue'
import { mapActions, mapState } from 'vuex'
import { analyticsEvents } from '../../constant/data'
/**
 * This component acts a dual-selector transfer.
 * It has search/filtering capabilites.
 * It is used in the sidepanel/global filters.
 */
export default {
  name: 'DualSelector',
  components: {
    ListSelector,
    DropdownWrapVue
  },
  props: {
    /** This prop contains the list that is used to populate. */
    options: {
      type: Array,
      default () {
        return []
      }
    },
    noOptionsText: {
      type: String,
      default: ''
    },
    id: {
      type: Number,
      default: 1
    },
    type: String,
    /** This prop defines the behaviour when a new item is selected */
    onChange: Function,
    /** This prop contains an array containing the information of selected objects */
    selected: Array,
    /** This prop contains/defines the text that appears as a label over the dropdown and for the filter itself */
    label: String,
    /** This prop contains the text when no item has been selected */
    placeholder: String,
    sidebarHeightApplied: Boolean,
    selectedItemsList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      selectedItems: [],
      removedItems: [],
      localSelectedItems: [],
      localSelectedItemsLen: 0,
      localRemovedItems: [],
      open: null,
      isAddActive: false,
      isRemoveActive: false,
      isVisible: false,
      dualList: null
    }
  },
  computed: {
    ...mapState('user', ['userDetails']),
    availableOptions () {
      if (this.selectedItems.length) {
        return this.options.filter((option) => {
          return !this.selectedItems.find(
            (selectedItem) => selectedItem.id === option.id
          )
        })
      }
      return this.options.length > 0 ? [...this.options] : []
    },
    dualSelectorLabel () {
      if (this.selectedItemsList.length === 1) {
        return this.selectedItemsList[0].name
      } else if (this.selectedItemsList.length > 1) {
        return 'Multiple'
      }
      return 'All'
    }
  },
  watch: {
    selectedItemsList () {
      this.selectedItems = this.selectedItemsList
    },
    selectedItems () {
      this.localSelectedItemsLen = this.selectedItems.length
    }
  },
  mounted () {
    this.dualList = document.querySelector(`#dual-selector-wrapper-${this.id}`)
    document.querySelector('#app').appendChild(this.dualList)
  },
  beforeDestroy () {
    if (this.dualList) document.querySelector('#app').removeChild(this.dualList)
  },
  methods: {
    ...mapActions('analytics', ['updateEvent']),
    ...mapActions('esgControversies', [
      'getNewsPublishers',
      'getEsgCompanyList'
    ]),
    onChangeSelectedOptions (selectedOptions) {
      // sorting the selected option to maintain the order
      selectedOptions.sort(function (first, second) {
        if (first.name > second.name) return 1
        if (first.name < second.name) return -1
        return 0
      })
      this.localSelectedItems = [...selectedOptions]
      if (this.localSelectedItems.length !== this.localSelectedItemsLen) {
        this.isAddActive = true
      } else {
        this.isAddActive = false
      }
    },
    onChangeRemovedOptions (removedOptions) {
      this.localRemovedItems = [...removedOptions]
      if (this.localRemovedItems.length) {
        this.isRemoveActive = true
      } else {
        this.isRemoveActive = false
      }
    },
    onSelectOptions () {
      if (this.isAddActive) {
        this.selectedItems = [...this.localSelectedItems]
        this.$emit('onChange', this.selectedItems)
      }
    },
    onUnselectOptions () {
      if (this.isRemoveActive) {
        this.removedItems = [...this.localRemovedItems]
        this.selectedItems = this.selectedItems.filter((selectedItem) => {
          return !this.localRemovedItems.find(
            (localRemovedItem) => localRemovedItem.id === selectedItem.id
          )
        })
        this.$emit('onChange', this.selectedItems)
      }
    },
    /**
     * This method closes the dropdown if a click is detected anywhere outside the boundary of the dropdown menu
     * @public
     */
    onClickOutside (event) {
      const closeButton = this.dualList.querySelector('.close-button-wrapper')
      const okButton = this.dualList.querySelector('.ok-btn')
      if (
        !(
          this.dualList &&
          this.dualList.contains(event.target) &&
          !closeButton.contains(event.target) &&
          !okButton.contains(event.target)
        )
      ) {
        this.open = null
      }
    },
    /**
     * This method opens the dropdown menu, and calls an *updateAnalytics()* method if the *updateAnalytics* prop is present
     * @public
     */
    openTab (index) {
      const elem = this.$refs[`dualSelectorFilter-${this.id}`]
      const leftArrow =
        this.dualList && this.dualList.querySelector('.left-arrow')
      if (this.open === null) {
        this.open = index
        this.dualList.style.left =
          elem.getBoundingClientRect().width + 39 + 'px'
        leftArrow.style.top = `calc(${
          elem.getBoundingClientRect().top
        }px - 80px - 20px + ${elem.getBoundingClientRect().height / 2}px)`
        this.dualList.style.height = `calc(100vh - ${this.dualList.style.top} - 20px)`
      } else {
        this.open = null
      }
      if (this.open) {
        this.updateEvent({
          userId: this.userDetails.userId,
          event: 'click',
          label:
            analyticsEvents[this.label.length ? this.label : this.placeholder],
          pageUrl: window.location.href
        })
      }
    }
  }
}
</script>

<style lang="scss">
.publisher-filter {
  position: relative;
}

.dual-selector-wrapper {
  width: 830px;
  height: 550px;
  display: flex;
  flex-direction: column;
  background-color: var(--dual-selector-background);
  padding: 15px 25px;
  box-shadow: 0 3px 14px 3px rgba(0, 0, 0, 0.2);
  top: 80px;
  position: absolute;
  z-index: 999;

  .left-arrow {
    display: block;
    position: absolute;
    left: -39px;
    width: 0;
    height: 0;
    border-color: transparent var(--dual-selector-background) transparent
      transparent;
    border-style: solid;
    border-width: 20px;
  }

  .dual-selector-inner-wrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1 1 auto;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .label {
      color: var(--dual-selector-title-color);
      font-size: 20px;
      font-weight: bold;
    }

    .icon {
      color: #b2b2b2;
      cursor: pointer;
    }
  }

  .dual-selector {
    width: 100%;
    display: flex;
    overflow: auto;
    height: 100%;
    gap: 35px;

    .select-actions {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 174px;

      .primary-btns {
        display: inline-flex;
        flex-direction: column;
        gap: 17px;
      }

      .btn {
        color: var(--dual-selector-button-text);
        background-color: var(--dual-selector-button-background);
        border: 1px solid var(--dual-selector-button-border-color);
        border-radius: 4px;
        font-size: 14px;
        font-weight: bold;
        width: 90px;
        height: 25px;
        cursor: pointer;

        span {
          display: block;
        }
      }

      .btn-disabled {
        opacity: 0.55;
        color: #b2b2b2;
        cursor: unset;
      }
    }
  }
}

@media screen and (min-width: 1700px) {
  .dual-selector-wrapper {
    width: 830px;
    height: 816px;
    padding: 26px 32px 35px;

    .header {
      margin-bottom: 32px;

      .label {
        font-size: 20px;
      }
    }

    .dual-selector {
      gap: 35px;

      .select-actions {
        gap: 174px;

        .primary-btns {
          gap: 17px;
        }

        .btn {
          font-size: 14px;
          font-weight: bold;
          width: 90px;
          height: 25px;
        }
      }
    }
  }
}
</style>

<style lang="scss" src="./common.scss" scoped></style>
