<template>
  <div class="side-panel-container">
    <div class="side-links">
        <!-- <div class="product-image">
          <company-partner-vue type="logo_dark" />
        </div> -->
        <slot></slot>
      <side-powered-by />
    </div>
  </div>
</template>

<script>
// import CompanyPartnerVue from '../CompanyPartner/CompanyPartner.vue'
import SidePoweredBy from './SidePoweredBy.vue'

/** This component renders the sidepanel. */
export default {
  name: 'SidePanel',
  components: {
    SidePoweredBy
    // CompanyPartnerVue
  }
}
</script>

<style lang="scss" scoped>
.side-panel-container {
  height: 100%;
  background: var(--sidebar-background-color);
  .product-image {
    display: flex;
    align-items: flex-end;
    height: 55px;
    img {
      width: 60px;
      object-fit: contain;
      height: 100%;
    }
  }
  .side-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .toggle-switch {
      padding: 0px 20px 10px 0px;
      display: flex;
      justify-content: flex-end;
    }
    .filter-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
      .each-dropdown {
        padding: 10px 20px;
      }
    }
  }
}
</style>
