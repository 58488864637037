<template>
  <side-panel-vue>
    <FilterHeader v-if="active_page !== 'profile' && !hideFilters" />
    <div
      v-if="active_page !== 'profile' && !hideFilters"
      class="filter-container"
      :class="[isCollapsed ? 'collapsed' : '']">
      <div class="each-dropdown">
        <MultiSelectorVue
          label="Report Types"
          placeholder="Select Report Types"
          :list="reportTypesList"
          :selected="selectedReportTypes"
          @onChange="onChangeReportTypes"
          :sidebarHeightApplied="true" />
      </div>
      <div class="each-dropdown company-filter dual-list-selector__filter">
        <DualSelector
          label="Company"
          placeholder="Select company"
          :options="reportsRepoCompanyList"
          :selected-items-list="selectedReportsRepoCompanies"
          @onChange="onChangeCompany"
          :id="1"
          :type="active_page"
          :sidebarHeightApplied="true"></DualSelector>
      </div>
      <div class="each-dropdown">
        <MultiSelectorVue
          label="Recommendation Types"
          placeholder="Select Recommendation Types"
          :list="recommendationTypesList"
          :selected="selectedRecommendationTypes"
          @onChange="onChangeRecommendationTypes"
          :sidebarHeightApplied="true" />
      </div>
      <div class="each-dropdown month-picker">
        <TimePeriodPicker :key="monthPickerKey" />
      </div>
    </div>
    <div
      v-if="active_page !== 'profile' && hideFilters"
      class="instructions-container"
      :class="[isCollapsed ? 'collapsed' : '']">
      <div class="sidepanel-title">Add Metadata</div>
      <div class="each-dropdown">
        <SingleSelectorVue
          label="Select Report"
          placeholder="Select Report"
          :list="rList"
          :selected="selectedUploadFileType"
          @onChange="onChangeSelectedUploadType"
          :sidebarHeightApplied="true" />
      </div>
      <template v-if="selectedUploadFileType.id">
        <div class="template-file-section">
          <div class="description">
            Click here to download the template for bulk upload
          </div>
          <div class="template-container">
            <div class="download-meta-info-btn" @click="downloadTemplate">
              <img
                src="@/assets/xlsx.png"
                alt="excel-icon"
                class="csv-icon" />
              <div class="action-text">Download CSV template</div>
            </div>
          </div>
          <div v-if="isMetaTemplateDownloaded" class="download-status-message description">
            {{selectedUploadFileType.name}} template csv downloaded
          </div>
        </div>
        <div class="upload-instructions">
          <span>Steps for bulk upload</span>
          <ol>
            <li class="step">Select the type of reports you want to upload.</li>
            <li class="step">
              Download the template and add the relevant report details.
            </li>
            <li class="step">
              Upload the template with the filled report metadata.
            </li>
            <li class="step">
              Continue to add the files that you want to showcase to the
              customer on the right file upload pane.
            </li>
          </ol>
        </div>
      </template>
    </div>
  </side-panel-vue>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MultiSelectorVue from '@/components/Dropdowns/MultiSelector.vue'
import SingleSelectorVue from '@/components/Dropdowns/SingleSelector.vue'
import TimePeriodPicker from '@/components/Dropdowns/TimePeriodPicker.vue'
import FilterHeader from '@/components/GlobalFilters/FilterHeader.vue'
import SidePanelVue from './SidePanel.vue'
import DualSelector from '../../Dropdowns/DualSelector.vue'
import { isEqual } from 'lodash'

/** This component contains the filters in the side panel */
export default {
  name: 'ReportSidePanel',
  components: {
    FilterHeader,
    MultiSelectorVue,
    SingleSelectorVue,
    SidePanelVue,
    TimePeriodPicker,
    DualSelector
  },
  data () {
    return {
      timePeriodList: [],
      toggle: false,
      monthPickerKey: 1,
      sidebarHeightApplied: true,
      isMetaTemplateDownloaded: false
    }
  },
  computed: {
    ...mapState('common', ['active_page', 'isCollapsed', 'currentModule']),
    ...mapState('reportsRepository', ['selectedUploadFileType']),
    ...mapState('reportsRepoFilters', [
      'selectedReportsRepoCompanies',
      'selectedReportsRepoCompaniesList',
      'selectedFICompanies',
      'reportsRepoCompanyList',
      'reportTypesList',
      'selectedReportTypes',
      'selectedRecommendationTypes',
      'recommendationTypesList'
    ]),
    ...mapState('user', ['userDetails']),
    hideFilters () {
      return this.$route.meta.hideFilters
    },
    parentReportId () {
      if (this.active_page === 'equityReports') {
        return 2
      } else if (this.active_page === 'fixedIncome') {
        return 3
      } else {
        return null
      }
    },
    rList () {
      const reportModule = this.userDetails.modules.find(module => module.module_name === 'reports-repository')
      return reportModule.moduleProperties.subTypes.map(item => { return { id: item.id, name: item.reportType, routeName: item.routeName } })
    }
  },
  watch: {
    '$route.name' (newValue, oldValue) {
      if (newValue === 'addMultipleReport' || newValue === 'addSingleReport') {
        const obj = this.rList.find(
          (obj) => obj.id === this.$route.params.reportTypeId
        )
        this.updateSelectedUploadFileType(obj)
      }
    },
    selectedUploadFileType () {
      this.isMetaTemplateDownloaded = false
    }
  },
  methods: {
    isEqual,
    ...mapActions('reportsRepoFilters', [
      'updateSelectedReportsRepoCompanies',
      'updateSelectedReportTypes',
      'updateSelectedRecommendationTypes',
      'getReportTypesList'
    ]),
    ...mapActions('reportsRepository', ['updateSelectedUploadFileType']),
    onChangeCompany (selectedCompany) {
      this.updateSelectedReportsRepoCompanies(selectedCompany)
    },
    onChangeReportTypes (selectedReportTypes) {
      this.updateSelectedReportTypes({
        id: this.parentReportId,
        list: selectedReportTypes
      })
    },
    onChangeRecommendationTypes (selectedRecommendationTypes) {
      this.updateSelectedRecommendationTypes({
        id: this.parentReportId,
        list: selectedRecommendationTypes
      })
    },
    onChangeSelectedUploadType (selectedUploadFileType) {
      this.updateSelectedUploadFileType(selectedUploadFileType)
      this.getReportTypesList(selectedUploadFileType.id)
    },
    downloadTemplate () {
      const filePath = this.getTemplateFilePath(this.selectedUploadFileType.id)
      const link = document.createElement('a')
      link.href = '/' + filePath
      link.download = filePath
      const vm = this
      link.addEventListener('click', () => {
        vm.isMetaTemplateDownloaded = true
      })
      link.click()
    },
    getTemplateFilePath (typeId) {
      switch (typeId) {
        case 2:
          return 'Equity Template.csv'
        case 3:
          return 'Fixed Income Template.csv'
      }
    }
  }
}
</script>

<style lang="scss" src="@/components/Dropdowns/common.scss" scoped></style>

<style lang="scss" scoped>
.side-panel-container {
  height: 100%;
  background: var(--sidebar-background-color);

  .product-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    img {
      width: 60px;
      object-fit: contain;
      height: 100%;
    }
  }
  .side-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .filter-container {
      overflow-y: auto;
      .each-dropdown {
        padding: 10px 20px;
        width: 225px;
        min-width: max-content; // This is to prevent displacement of filter text when the sidepanel is expanded
      }
    }
    .collapsed {
      display: none;
    }
  }
}

::v-deep .instructions-container {
  padding: 0 25px;
  .sidepanel-title {
    text-transform: uppercase;
    color: white;
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: 0.75px solid var(--inava-primary-light);
  }
  .each-dropdown {
    margin-top: 15px;
    margin-bottom: 15px;
    .top-label {
      color: white;
    }
  }
  .template-file-section {
    color: white;
    font-size: 12px;
    .description {
      margin-bottom: 15px;
    }
    .template-container {
      display: flex;
      border: 1px dashed var(--inava-primary-light);
      border-radius: 4px;
      padding: 15px 20px;
      margin-bottom: 15px;
      transition: 0.1s ease-in;
      .download-meta-info-btn {
        display: flex;
      }
      &:hover {
        cursor: pointer;
        border: 1px solid var(--inava-primary);
        .csv-icon {
          opacity: 1;
        }
        .action-text {
          color: white;
        }
      }
      .csv-icon {
        width: 25px;
        height: 28px;
        margin-right: 10px;
        object-fit: contain;
        opacity: 0.4;
        transition: 0.1s ease-in;
      }
      .action-text {
        color: #c7c7c7;
        font-size: 12px;
        transition: 0.1s ease-in;
      }
    }
    .download-status-message {
      color: #F57777;
    }
  }
  .upload-instructions {
    font-size: 12px;
    span {
      color: white;
    }
    ol {
      margin-left: 15px;
      li {
        color: #c7c7c7;
        &::marker {
          color: var(--inava-primary);
        }
      }
    }
  }
}

@media screen and (min-width: 1700px) {
  ::v-deep .instructions-container {
    padding: 0 25px;
    .sidepanel-title {
      font-size: 16px;
      margin-top: 45px;
      padding-bottom: 15px;
    }
    .each-dropdown {
      margin-top: 25px;
      margin-bottom: 25px;
    }
    .template-file-section {
      font-size: 14px;
      .description {
        margin-bottom: 12px;
      }
      .template-container {
        padding: 15px 20px;
        margin-bottom: 50px;
        .csv-icon {
          width: 33px;
          height: 37px;
          margin-right: 10px;
        }
        .action-text {
          font-size: 14px;
        }
      }
    }
    .upload-instructions {
      font-size: 14px;
      ol {
        margin-left: 15px;
      }
    }
  }
}
</style>
