<template>
  <side-panel-vue>
    <FilterHeader v-if="active_page !== 'profile'" />
    <div
      v-if="active_page !== 'profile'"
      class="filter-container"
      :class="[isCollapsed ? 'collapsed' : '']">
      <div class="each-dropdown company-filter dual-list-selector__filter">
        <DualSelector
          label="Company"
          placeholder="Select company"
          :options="esgCompaniesList"
          :selected-items-list="selectedEsgCompaniesList"
          @onChange="onChangeCompany"
          :id="1"
          :type="'company'"
          :sidebarHeightApplied="true"></DualSelector>
      </div>
      <div class="each-dropdown" v-show="portfolioList && portfolioList.length">
        <MultiSelectorVue
          label="Portfolio"
          placeholder="Select Portfolio"
          :list="portfolioList"
          :selected="selectedPortfolios"
          @onChange="onChangePortfolio"
          :sidebarHeightApplied="true" />
      </div>
      <div class="each-dropdown">
        <div
          :class="`${
            sidebarHeightApplied
              ? 'data-source-container-sidebar'
              : 'data-source-container'
          }`">
          <div class="top-label">Category</div>
          <treeselect
            ref="treeselectMenu"
            :multiple="true"
            :disable-branch-nodes="false"
            :options="esgCategoriesList"
            :default-expand-level="1"
            placeholder="Select Categories"
            :closeOnSelect="false"
            :clearable="false"
            :openOnFocus="true"
            :backspaceRemoves="false"
            :deleteRemoves="false"
            :value="selectedEsgCategories"
            :value-consists-of="'ALL'"
            :searchable="false"
            @input="onChangeSelectedEsgCategories"
            @open="handleTreeselectOpen"
            @select="handleSelectOption"
            @deselect="handleSelectOption"
            :limit="0"
            :alwaysOpen="false"
            :limitText="labelTextSetter">
            <div
              v-show="esgCategoriesList.length"
              class="vue-treeselect__list select-all-option"
              @mouseenter="addHighlight($event)"
              @mouseleave="removeHighlight($event)"
              slot="before-list"
              @click.capture="toggleSelectAllCategories($event)">
              <div
                class="vue-treeselect__list-item vue-treeselect__indent-level-0">
                <div
                  class="vue-treeselect__option vue-treeselect__option--selected">
                  <div class="vue-treeselect__option-arrow-placeholder">
                    &nbsp;
                  </div>
                  <div class="vue-treeselect__label-container">
                    <div class="vue-treeselect__checkbox-container">
                      <span
                        class="vue-treeselect__checkbox vue-treeselect__checkbox--checked">
                        <span class="vue-treeselect__check-mark"></span>
                        <span class="vue-treeselect__minus-mark"></span>
                      </span>
                    </div>
                    <label class="vue-treeselect__label">Select All</label>
                    <input
                      ref="selectAllCheckbox"
                      type="checkbox"
                      id="selectAll"
                      name="selectAll" />
                  </div>
                </div>
              </div>
            </div>
          </treeselect>
        </div>
      </div>
      <div class="each-dropdown month-picker">
        <TimePeriodPicker :key="monthPickerKey" />
      </div>
      <div class="each-dropdown publisher-filter dual-list-selector__filter">
        <DualSelector
          label="Publisher"
          placeholder="Select Publisher"
          :options="publisherList"
          :selected-items-list="selectedPublishersList"
          @onChange="onChangePublisher"
          :id="2"
          :type="'publisher'"
          :sidebarHeightApplied="true"></DualSelector>
      </div>
      <div class="each-dropdown">
        <MultiSelectorVue
          label="Sentiments"
          placeholder="Select Sentiments"
          :list="sentimentList"
          :selected="selectedSentiment"
          @onChange="onChangeSentiment"
          :sidebarHeightApplied="true" />
      </div>
      <div class="each-dropdown">
        <MultiSelectorVue
          label="Sector (Coming Soon)"
          placeholder="All"
          :list="[]"
          :selected="[]"
          :sidebarHeightApplied="true" />
      </div>
    </div>
  </side-panel-vue>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FilterHeader from '@/components/GlobalFilters/FilterHeader.vue'
import MultiSelectorVue from '@/components/Dropdowns/MultiSelector.vue'
import SidePanelVue from './SidePanel.vue'
import TimePeriodPicker from '@/components/Dropdowns/TimePeriodPicker.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DualSelector from '@/components/Dropdowns/DualSelector.vue'
import { isEqual } from 'lodash'

/** This component contains the filters in the side panel */
export default {
  name: 'GlobalFilters',
  components: {
    FilterHeader,
    MultiSelectorVue,
    SidePanelVue,
    TimePeriodPicker,
    Treeselect,
    DualSelector
  },
  data () {
    return {
      timePeriodList: [],
      toggle: false,
      monthPickerKey: 1,
      sidebarHeightApplied: true,
      observer: null
    }
  },
  computed: {
    ...mapState('esgControversies', [
      'sentimentList',
      'selectedSentiment',
      'portfolioList',
      'selectedPortfolios',
      'publisherList',
      'selectedPublishersList',
      'esgCompaniesList',
      'selectedEsgCompaniesList'
    ]),
    ...mapState('newsCategories', [
      'categories',
      'totalCount',
      'totalLength',
      'parentNewsCategories'
    ]),
    ...mapState('common', ['active_page', 'isCollapsed', 'currentModule']),
    ...mapState('filters', [
      'esgCategoriesList',
      'selectedEsgCategories',
      'esgCategoriesListFlat'
    ]),
    ...mapState('user', ['userDetails'])
  },
  mounted () {
    if (this.userDetails) {
      this.getNewsCategoryListGlobalFilter({
        moduleId: this.userDetails.modules.find(
          (module) => module.module_name === this.currentModule
        ).module_id,
        limit: 200,
        offset: 0
      })
    }
    this.getPortfolioList()
    if (this.$refs.treeselectMenu) {
      const targetNode = this.$refs.treeselectMenu.$el
      const config = { attributes: false, childList: true, subtree: true }
      this.observer = new MutationObserver((mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.addedNodes.length && (mutation.addedNodes[0].nodeType !== Node.COMMENT_NODE)) {
            document.querySelectorAll('label.vue-treeselect__label').forEach(label => {
              label.title = label.innerText
            })
          }
        }
      })
      this.observer.observe(targetNode, config)
    }
  },
  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    isEqual,
    ...mapActions('esgControversies', [
      'updateSelectedSentiments',
      'updateSelectedPublishers',
      'updateSelectedEsgCompanies',
      'getPortfolioList',
      'updateSelectedPortfolios'
    ]),
    ...mapActions('filters', [
      'getNewsCategoryListGlobalFilter',
      'updateSelectedEsgCategories'
    ]),
    /** This event handler updates the selected sources
     * @public
     */
    onChangeSentiment (selectedSentiments) {
      this.updateSelectedSentiments(selectedSentiments)
    },
    onChangePortfolio (portfolioValue) {
      this.updateSelectedPortfolios(portfolioValue)
    },
    onChangeSelectedEsgCategories (data) {
      this.updateSelectedEsgCategories(data)
    },
    labelTextSetter (count) {
      const allSelected = this.checkIfAllTreeselectOptionsAreSelected()
      if (allSelected) return 'All'
      if (count === 1) {
        return this.esgCategoriesListFlat.find(
          (category) => category.id === this.selectedEsgCategories[0]
        ).label
      }
      if (count > 1 && count !== this.esgCategoriesListFlat.length) {
        return 'Multiple'
      }
    },
    checkIfAllTreeselectOptionsAreSelected () {
      const customCheckbox = document.querySelector(
        '.select-all-option .vue-treeselect__checkbox'
      )
      const allOptionsSelected = this.isEqual(
        new Set(this.selectedEsgCategories),
        new Set(this.esgCategoriesListFlat.map((c) => c.id))
      )
      if (this.$refs.selectAllCheckbox) {
        if (
          this.selectedEsgCategories &&
          this.esgCategoriesList &&
          allOptionsSelected
        ) {
          customCheckbox.classList.remove('vue-treeselect__checkbox--unchecked')
          customCheckbox.classList.add('vue-treeselect__checkbox--checked')
          this.$refs.selectAllCheckbox.checked = true
        } else {
          customCheckbox.classList.remove('vue-treeselect__checkbox--checked')
          customCheckbox.classList.add('vue-treeselect__checkbox--unchecked')
          this.$refs.selectAllCheckbox.checked = false
        }
      }
      return allOptionsSelected
    },
    toggleSelectAllCategories (event) {
      const allSelected = this.checkIfAllTreeselectOptionsAreSelected()
      const listOfAllCategoryIds = this.esgCategoriesListFlat.map(
        (category) => category.id
      )
      const customCheckbox = document.querySelector(
        '.select-all-option .vue-treeselect__checkbox'
      )
      if (!allSelected) {
        customCheckbox.classList.remove('vue-treeselect__checkbox--unchecked')
        customCheckbox.classList.add('vue-treeselect__checkbox--checked')
        this.$refs.selectAllCheckbox.checked = true
        this.updateSelectedEsgCategories(listOfAllCategoryIds)
      } else {
        customCheckbox.classList.remove('vue-treeselect__checkbox--checked')
        customCheckbox.classList.add('vue-treeselect__checkbox--unchecked')
        this.$refs.selectAllCheckbox.checked = false
        this.updateSelectedEsgCategories([])
      }
    },
    handleTreeselectOpen (value, instanceId) {
      this.$nextTick(() => {
        this.checkIfAllTreeselectOptionsAreSelected()
      })
    },
    handleSelectOption (value, instanceId) {
      this.$nextTick(() => {
        this.checkIfAllTreeselectOptionsAreSelected()
      })
    },
    addHighlight (event) {
      const highlightedElem = document.querySelector(
        '.vue-treeselect__option.vue-treeselect__option--highlight'
      )
      const elem = document.querySelector('.select-all-option')
      if (highlightedElem) {
        highlightedElem.classList.remove('vue-treeselect__option--highlight')
      }
      if (elem) {
        elem.classList.add('vue-treeselect__option--highlight')
      }
    },
    removeHighlight (event) {
      const elem = document.querySelector('.select-all-option')
      elem.classList.remove('vue-treeselect__option--highlight')
    },
    onChangePublisher (selectedPublisher) {
      this.updateSelectedPublishers(selectedPublisher)
    },
    onChangeCompany (selectedCompany) {
      this.updateSelectedEsgCompanies(selectedCompany)
    }
  }
}
</script>

<style lang="scss" src="@/components/Dropdowns/common.scss" scoped></style>

<style lang="scss" scoped>
::v-deep .vue-treeselect {
  max-width: 185px;
  cursor: pointer;
  .vue-treeselect__placeholder {
    color: #eff3f6cc;
    line-height: unset;
    font-size: 11px;
    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal 12px/15px Quicksand;
      font-weight: 600;
    }
    @media (min-width: 1700px) {
      font: normal normal 14px/18px Quicksand;
      font-weight: 600;
    }
    display: flex;
    align-items: center;
    padding-left: 0;
    font-weight: 500;
  }
  // Always keep .vue-treeselect-helper-hide class below the .vue-treeselect__placeholder class
  .vue-treeselect-helper-hide {
    display: none;
  }
  .vue-treeselect__input-container {
    padding-top: 0;
  }
  .vue-treeselect__input {
    color: #eff3f6cc;
    font-size: 11px;
    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal 12px/15px Quicksand;
      font-weight: 600;
    }
    @media (min-width: 1700px) {
      font: normal normal 14px/18px Quicksand;
      font-weight: 600;
    }
  }
  .vue-treeselect__control {
    background: rgba(196, 200, 248, 0.2);
    border: none;
    border-radius: 5px;
    height: 24px;
    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal 12px/15px Quicksand;
      font-weight: 600;
      height: 27px;
    }
    @media (min-width: 1700px) {
      font: normal normal 14px/18px Quicksand;
      font-weight: 600;
      height: 33px;
    }
    padding-left: 15px;
    .vue-treeselect__multi-value {
      margin-bottom: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }
  .vue-treeselect__menu {
    background-color: var(--sidebar-option-background-color);
    border: none;
    border-radius: 5px;
    line-height: 155%;
    top: 1px;
    margin-top: 0;
    padding: 0;
    .select-all-option {
      input {
        display: none;
      }
    }
    .vue-treeselect__list {
      color: #fff;
      font-size: 11px;
      background-color: var(--sidebar-option-background-color);
      .vue-treeselect__option {
        .vue-treeselect__label {
          padding-left: 0;
        }
        .vue-treeselect__checkbox {
          margin: 0;
        }
        :where(.vue-treeselect__checkbox--checked, .vue-treeselect__checkbox--indeterminate) {
          border-color: var(--inava-primary);
          background: var(--inava-primary);
        }
        .vue-treeselect__label-container:hover
          .vue-treeselect__checkbox--unchecked {
          border-color: var(--inava-primary);
        }
      }
    }
  }
  .vue-treeselect__option--highlight {
    background-color: var(--hover-sidebar-option-background-color);
  }
  .vue-treeselect__limit-tip {
    padding-top: 0;
    vertical-align: middle;
  }
  .vue-treeselect__tip-text,
  .vue-treeselect__limit-tip-text {
    color: #eff3f6cc;
    font-size: 11px;
    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal 12px/15px Quicksand;
      font-weight: 600;
    }
    @media (min-width: 1700px) {
      font: normal normal 14px/18px Quicksand;
      font-weight: 600;
    }
  }
  .vue-treeselect__no-results-tip,
  .vue-treeselect__no-options-tip {
    padding: 5px;
  }
}
.side-panel-container {
  height: 100%;
  background: var(--sidebar-background-color);

  .product-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    img {
      width: 60px;
      object-fit: contain;
      height: 100%;
    }
  }
  .side-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .filter-container {
      overflow-y: auto;
      .each-dropdown {
        padding: 10px 20px;
        width: 225px;
        min-width: max-content; // This is to prevent displacement of filter text when the sidepanel is expanded
      }
    }
    .collapsed {
      display: none;
    }
  }
}

.publisher-filter {
}
</style>
