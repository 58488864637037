<template>
  <div class="data-source-container month-range-picker">
    <div class="top-label">Time period</div>
    <date-picker
      input-class="mx-input"
      ref="rangePicker"
      :shortcuts="shortcuts"
      v-model="date"
      lang="en"
      type="month"
      value-type="format"
      format="YYYY-MM-DD"
      :range="isRange"
      :editable="isEditable"
      :clearable="isClearable"
      :disabled-date="disabledOutsideSubs"
      @open="prepareYearAttr"
      @close="handleRangeClose"
      @change="updateTimePeriod"
      @pick="handelPick"
    >
      <template v-slot:header
        >{{ date[0] | dateFormatter }} ~ {{ date[1] | dateFormatter }}
      </template>
      <template v-slot:input :slot-scope="picker">
        <div v-if="customRangevalue" class="mx-input">
          {{ customRangevalue }}
        </div>
        <div v-else class="mx-input">
          {{ date[0] | dateFormatter }} ~ {{ date[1] | dateFormatter }}
        </div>
      </template>
      <template v-slot:footer="{ emit }" v-if="subscriptionGranularity">
        <div>
          <b-radio v-model="granularity" native-value="month" type="is-info">
            Monthly
          </b-radio>
          <b-radio v-model="granularity" native-value="quarter" type="is-info">
            Quarterly
          </b-radio>
        </div>
        <div
          v-if="
            calanderOpened &&
            (currentPath === 'trends' || currentPath === 'voeTrends')
          "
          class="period-selector"
        >
          <div class="head">Compare to</div>
          <b-select
            placeholder="Select a name"
            size="is-small"
            v-model="compareType"
          >
            <option
              v-for="option in options"
              :value="option.value"
              :key="option.value"
            >
              {{ option.name }}
            </option>
          </b-select>
          <div class="from-input">
            <div>From</div>
            <!-- pattern="(?:19|20)\[0-9\]{2}-(?:(?:0\[1-9\]|1\[0-2\])-(?:0\[1-9\]|1\[0-9\]|2\[0-9\])|(?:(?!02)(?:0\[1-9\]|1\[0-2\])-(?:30))|(?:(?:0\[13578\]|1\[02\])-31))" -->
            <b-field grouped>
              <b-select
                placeholder="MMM"
                class="datepart1"
                v-model.number="fromMonth"
                :disabled="compareType === 0"
                @input="handleFromChange"
                size="is-small"
              >
                <option
                  v-for="month in months"
                  :value="month.value"
                  :key="month.label"
                >
                  {{ month.label }}
                </option>
              </b-select>
              <b-select
                placeholder="YYYY"
                class="datepart3"
                v-model.number="fromYear"
                :disabled="compareType === 0"
                @input="handleFromChange"
                size="is-small"
              >
                <option
                  v-for="year in subscribedYears"
                  :value="year"
                  :key="year"
                >
                  {{ year }}
                </option>
              </b-select>
              <div class="errorMessage from-error">
                {{ compareFromErrorMessage }}
              </div>
            </b-field>
          </div>
          <div class="from-input">
            <div>To</div>
            <b-field grouped>
              <b-select
                placeholder="MMM"
                class="datepart1"
                v-model.number="toMonth"
                :disabled="compareType === 0"
                @input="handleToChange"
                size="is-small"
              >
                <option
                  v-for="month in months"
                  :value="month.value"
                  :key="month.label"
                >
                  {{ month.label }}
                </option>
              </b-select>
              <b-select
                placeholder="YYYY"
                class="datepart3"
                v-model.number="toYear"
                :disabled="compareType === 0"
                @input="handleToChange"
                size="is-small"
              >
                <option
                  v-for="year in subscribedYears"
                  :value="year"
                  :key="year"
                >
                  {{ year }}
                </option>
              </b-select>
              <div class="errorMessage to-error">
                {{ compareToErrorMessage }}
              </div>
            </b-field>
          </div>
          <div>
            <!-- <b-button
              size="is-small"
              type="is-ghost"
              :disabled="compareType === 0"
              @click="handleCompare"
              class="ghost-btn">Compare</b-button> -->
            <div class="errorMessage compare-error">
              {{ compareErrorMessage }}
            </div>
          </div>
        </div>
      </template>
    </date-picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mapState, mapActions } from 'vuex'
import { getCustomDateRange } from '../../util/util'
import moment from 'moment'
import * as d3 from 'd3'
import { analyticsEvents, calendarOptions } from '../../constant/data'

export default {
  name: 'MonthRangePicker',
  components: {
    DatePicker
  },
  filters: {
    dateFormatter(val) {
      return val ? moment(val, 'YYYY-MM-DD').format('MMM-YYYY') : ''
    }
  },
  data() {
    return {
      date: [],
      granularity: '', // 'quarter',
      isClearable: false,
      isRange: true,
      isEditable: false,
      selectedButton: null,
      calendarOptions,
      customRangevalue: '',
      calanderOpened: false,
      compareFrom: '',
      compareTo: '',
      options: [
        { name: 'Previous period', value: 0 },
        { name: 'Custom period', value: 1 }
      ],
      compareType: 0,
      // fromDay: null,
      fromMonth: null,
      fromYear: null,
      // toDay: null,
      toMonth: null,
      toYear: null,
      compareFromDate: null,
      compareToDate: null,
      compareErrorMessage: null,
      compareFromErrorMessage: null,
      compareToErrorMessage: null,
      defaultPreviousPeriod: [],
      months: [
        { label: 'Jan', value: 1 },
        { label: 'Feb', value: 2 },
        { label: 'Mar', value: 3 },
        { label: 'Apr', value: 4 },
        { label: 'May', value: 5 },
        { label: 'Jun', value: 6 },
        { label: 'Jul', value: 7 },
        { label: 'Aug', value: 8 },
        { label: 'Sep', value: 9 },
        { label: 'Oct', value: 10 },
        { label: 'Nov', value: 11 },
        { label: 'Dec', value: 12 }
      ]
      // years: [2016, 2017, 2018, 2019, 2020, 2021]
      // currentPath: ''
      // subscribedRange: [this.subscriptionRange.startDate, this.subscriptionRange.endDate] // [this.$startDate, this.$endDate]
    }
  },
  computed: {
    ...mapState('filters', [
      'ActiveVocTimePeriod',
      'ActiveVoeTimePeriod',
      'ActiveVendorCsatTimePeriod',
      'subscriptionRange',
      'subscriptionGranularity',
      'isInitialLoadDone',
      'previousPeriodRange'
    ]),
    ...mapState('user', ['userDetails']),
    // eslint-disable-next-line vue/return-in-computed-property
    ActiveTimePeriod() {
      if (this.$route.meta.module === 'voe') {
        return this.ActiveVoeTimePeriod
      } else if (this.$route.meta.module === 'voc') {
        if (this.currentPath === 'vendor-csat') return this.ActiveVendorCsatTimePeriod
        return this.ActiveVocTimePeriod
      }
    },
    currentModule() {
      return this.$route.meta.module
    },
    currentPath() {
      return this.$route.name
    },
    subscribedRange() {
      return [this.subscriptionRange.startDate, this.subscriptionRange.endDate] // [this.$startDate, this.$endDate]
    },
    subscribedYears() {
      const a = moment(this.subscriptionRange.startDate, 'YYYY-MM-DD').year()
      const b = moment(this.subscriptionRange.endDate, 'YYYY-MM-DD').year()
      const years = []
      let year = a
      const currentPeriodStart = moment(
        this.ActiveTimePeriod.range[0],
        'YYYY-MM-DD'
      ).year()
      for (let i = 0; i < Math.abs(a - b); i++) {
        if (year <= currentPeriodStart) {
          years.push(year++)
        }
      }
      if (b <= currentPeriodStart) {
        years.push(b)
      }
      // years.push(b)
      return years
    },
    // defaultPreviousPeriod () {
    //   const monthCount = moment(this.ActiveTimePeriod.range[1], 'YYYY-MM-DD').diff(moment(this.ActiveTimePeriod.range[0], 'YYYY-MM-DD'), 'month') + 1
    //   const previousStartDate = moment(this.ActiveTimePeriod.range[0], 'YYYY-MM-DD').subtract(monthCount, 'month').format('YYYY-MM-DD')
    //   const previousEndDate = moment(this.ActiveTimePeriod.range[1], 'YYYY-MM-DD').subtract(monthCount, 'month').format('YYYY-MM-DD')
    //   return [previousStartDate, previousEndDate]
    // },
    shortcuts() {
      const options = []
      if (
        moment().subtract(11, 'month').startOf('month') >=
          moment(this.subscriptionRange.startDate).startOf('month') &&
        moment().startOf('month') <=
          moment(this.subscriptionRange.endDate).endOf('month')
      ) {
        options.push({
          text: 'Last 12 months',
          onClick: () => {
            this.updateRange('lastNMonths', 11)
          }
        })
      }
      options.push({
        text: 'All time',
        onClick: () => {
          this.selectedButton = 'allTime'
          this.updateRange('allTime', null, this.subscribedRange)
        }
      })
      if (
        moment().startOf('month') <=
        moment(this.subscriptionRange.endDate).endOf('month')
      ) {
        options.push({
          text: 'Current month',
          onClick: () => {
            this.updateRange('currentMonth')
          }
        })
      }
      if (
        moment().subtract(1, 'month').startOf('month') <
        moment(this.subscriptionRange.endDate).endOf('month')
      ) {
        options.push({
          text: 'Previous month',
          onClick: () => {
            this.updateRange('previousMonth')
          }
        })
      }
      if (
        moment().startOf('year') >=
          moment(this.subscriptionRange.startDate).startOf('month') &&
        moment().endOf('year') <=
          moment(this.subscriptionRange.endDate).endOf('month')
      ) {
        options.push({
          text: 'Current year',
          onClick: () => {
            this.updateRange('currentYear')
          }
        })
      }
      if (
        moment().subtract(1, 'year').startOf('year') >=
          moment(this.subscriptionRange.startDate).startOf('month') &&
        moment().subtract(1, 'year').endOf('year') <=
          moment(this.subscriptionRange.endDate).endOf('month')
      ) {
        options.push({
          text: 'Previous year',
          onClick: () => {
            this.updateRange('previousYear')
          }
        })
      }
      if (
        moment().subtract(23, 'month').startOf('month') >=
          moment(this.subscriptionRange.startDate).startOf('month') &&
        moment().startOf('month') <=
          moment(this.subscriptionRange.endDate).endOf('month')
      ) {
        options.push({
          text: 'Last 24 months',
          onClick: () => {
            this.updateRange('lastNMonths', 23)
          }
        })
      }
      return options
    }
  },
  watch: {
    ActiveTimePeriod() {
      this.checkCustomRange()
      if (this.date !== this.ActiveTimePeriod.range) {
        this.date = this.ActiveTimePeriod.range
      }
      if (this.ActiveTimePeriod && this.ActiveTimePeriod.group_by) {
        this.granularity = this.ActiveTimePeriod.group_by
      } else {
        this.granularity = this.subscriptionGranularity ? 'month' : 'quarter'
      }
    },
    granularity() {
      this.updateGranularity(this.granularity)
    },
    compareFromDate() {
      if (
        this.compareFromDate < this.subscriptionRange.startDate ||
        this.compareFromDate > this.subscriptionRange.endDate
      ) {
        this.compareFromErrorMessage = 'Date out of range'
      } else {
        this.compareFromErrorMessage = null
      }
      if (this.compareFromDate && this.compareToDate) {
        this.updatePreviousPeriodRange([
          this.compareFromDate,
          this.compareToDate
        ])
      }
      this.checkPreviousRangeValidity()
    },
    compareToDate() {
      if (
        this.compareToDate < this.subscriptionRange.startDate ||
        this.compareToDate > this.subscriptionRange.endDate
      ) {
        this.compareToErrorMessage = 'Date out of range'
      } else {
        this.compareToErrorMessage = null
      }
      if (this.compareFromDate && this.compareToDate) {
        this.updatePreviousPeriodRange([
          this.compareFromDate,
          this.compareToDate
        ])
      }
      this.checkPreviousRangeValidity()
    },
    compareType() {
      if (this.compareType === 0) {
        // set default
        this.setValuesFromDefaultPeriod()
      } else {
        // set custom
      }
    },
    date() {
      this.setDefaultPreviousPeriodRange()
    }
  },
  mounted() {
    if (this.ActiveTimePeriod) {
      this.date = this.ActiveTimePeriod.range
      this.granularity = this.ActiveTimePeriod.group_by
      this.setDefaultPreviousPeriodRange()
    }
  },
  methods: {
    ...mapActions('filters', [
      'updateActiveVocTimePeriod',
      'updateActiveVendorCsatTimePeriod',
      'updateActiveVoeTimePeriod',
      'updatePreviousPeriodRange',
      'updateIsPreviousPeriodInSubscription'
    ]),
    ...mapActions('analytics', ['updateEvent']),
    /**
     * This method checks if there is a valid previous range available for the user selection
     * @public
     */
    checkPreviousRangeValidity() {
      if (
        this.compareFromDate > this.compareToDate ||
        this.compareFromDate < this.subscriptionRange.startDate ||
        this.compareFromDate > this.subscriptionRange.endDate ||
        this.compareToDate < this.subscriptionRange.startDate ||
        this.compareToDate > this.subscriptionRange.endDate ||
        moment(this.compareFromDate).isBetween(
          this.ActiveTimePeriod.range[0],
          this.ActiveTimePeriod.range[1],
          undefined,
          '[]'
        ) ||
        moment(this.compareToDate).isBetween(
          this.ActiveTimePeriod.range[0],
          this.ActiveTimePeriod.range[1],
          undefined,
          '[]'
        )
      ) {
        this.updateIsPreviousPeriodInSubscription(false)
        if (this.compareFromDate > this.compareToDate) {
          this.compareErrorMessage = 'From-date must be before To-date'
        }
        if (
          moment(this.compareFromDate).isBetween(
            this.ActiveTimePeriod.range[0],
            this.ActiveTimePeriod.range[1],
            undefined,
            '[]'
          )
        ) {
          this.compareErrorMessage =
            'previous period should not overlap with current period'
        }
        if (
          moment(this.compareToDate).isBetween(
            this.ActiveTimePeriod.range[0],
            this.ActiveTimePeriod.range[1],
            undefined,
            '[]'
          )
        ) {
          this.compareErrorMessage =
            'previous period should not overlap with current period'
        }
        // Add message for subscription errors here
      } else {
        this.updateIsPreviousPeriodInSubscription(true)
        this.compareErrorMessage = null
      }
    },
    /**
     * This method sets the default previous range (*It is set as 1 month*)
     * @public
     */
    setDefaultPreviousPeriodRange() {
      // if (this.currentModule === 'voe') {
      //   const monthCount = moment(this.ActiveVoeTimePeriod.range[1], 'YYYY-MM-DD').diff(moment(this.ActiveVoeTimePeriod.range[0], 'YYYY-MM-DD'), 'month') + 1
      //   const previousStartDate = moment(this.ActiveVoeTimePeriod.range[0], 'YYYY-MM-DD').subtract(monthCount, 'month').format('YYYY-MM-DD')
      //   const previousEndDate = moment(this.ActiveVoeTimePeriod.range[1], 'YYYY-MM-DD').subtract(monthCount, 'month').format('YYYY-MM-DD')
      //   this.defaultPreviousPeriod = [previousStartDate, previousEndDate]
      //   this.setValuesFromDefaultPeriod()
      // } else {
      const monthCount =
        moment(this.ActiveTimePeriod.range[1], 'YYYY-MM-DD').diff(
          moment(this.ActiveTimePeriod.range[0], 'YYYY-MM-DD'),
          'month'
        ) + 1
      const previousStartDate = moment(
        this.ActiveTimePeriod.range[0],
        'YYYY-MM-DD'
      )
        .subtract(monthCount, 'month')
        .format('YYYY-MM-DD')
      const previousEndDate = moment(
        this.ActiveTimePeriod.range[1],
        'YYYY-MM-DD'
      )
        .subtract(monthCount, 'month')
        .format('YYYY-MM-DD')
      this.defaultPreviousPeriod = [previousStartDate, previousEndDate]
      this.setValuesFromDefaultPeriod()
      // }
    },
    /**
     * This method sets the values from the default period
     * @public
     */
    setValuesFromDefaultPeriod() {
      this.fromMonth =
        moment(this.defaultPreviousPeriod[0], 'YYYY-MM-DD').month() + 1
      this.fromYear = moment(this.defaultPreviousPeriod[0], 'YYYY-MM-DD').year()
      this.handleFromChange()
      this.toMonth =
        moment(this.defaultPreviousPeriod[1], 'YYYY-MM-DD').month() + 1
      this.toYear = moment(this.defaultPreviousPeriod[1], 'YYYY-MM-DD').year()
      this.handleToChange()
    },

    /**
     * This method calls the *prepareCompareDate()* method when the **from** date is changed
     * @public
     */
    handleFromChange(value) {
      if (this.fromMonth && this.fromYear) {
        this.prepareCompareDate('from')
      }
    },

    /**
     * This method calls the *prepareCompareDate()* method when the **from** date is changed
     * @public
     */
    handleToChange(value) {
      if (this.toMonth && this.toYear) {
        this.prepareCompareDate('to')
      }
    },

    prepareCompareDate(type) {
      if (type === 'from') {
        this.compareFromDate = moment(
          this.fromYear + '-' + this.fromMonth,
          'YYYY-MM'
        ).format('YYYY-MM-DD')
        if (
          moment(this.compareFromDate, 'YYYY-MM-DD').month() ===
            moment(this.subscriptionRange.startDate, 'YYYY-MM-DD').month() &&
          moment(this.compareFromDate, 'YYYY-MM-DD').year() ===
            moment(this.subscriptionRange.startDate, 'YYYY-MM-DD').year()
        ) {
          this.compareFromDate = this.subscriptionRange.startDate
        }
        if (
          moment(this.compareFromDate, 'YYYY-MM-DD').month() ===
            moment(this.subscriptionRange.endDate, 'YYYY-MM-DD').month() &&
          moment(this.compareFromDate, 'YYYY-MM-DD').year() ===
            moment(this.subscriptionRange.endDate, 'YYYY-MM-DD').year()
        ) {
          this.compareFromDate = this.subscriptionRange.endDate
        }
      }
      if (type === 'to') {
        this.compareToDate = moment(this.toYear + '-' + this.toMonth, 'YYYY-MM')
          .endOf('month')
          .format('YYYY-MM-DD')
        if (
          moment(this.compareToDate, 'YYYY-MM-DD').month() ===
            moment(this.subscriptionRange.startDate, 'YYYY-MM-DD').month() &&
          moment(this.compareToDate, 'YYYY-MM-DD').year() ===
            moment(this.subscriptionRange.startDate, 'YYYY-MM-DD').year()
        ) {
          this.compareToDate = this.subscriptionRange.startDate
        }
        if (
          moment(this.compareToDate, 'YYYY-MM-DD').month() ===
            moment(this.subscriptionRange.endDate, 'YYYY-MM-DD').month() &&
          moment(this.compareToDate, 'YYYY-MM-DD').year() ===
            moment(this.subscriptionRange.endDate, 'YYYY-MM-DD').year()
        ) {
          this.compareToDate = this.subscriptionRange.endDate
        }
      }
    },

    handleCompare() {
      if (!this.compareFromDate) {
        this.compareFromErrorMessage = 'Date is required'
      } else {
        this.compareFromErrorMessage = this.compareFromErrorMessage
          ? this.compareFromErrorMessage
          : null
      }
      if (!this.compareToDate) {
        this.compareToErrorMessage = 'Date is required'
      } else {
        this.compareToErrorMessage = this.compareToErrorMessage
          ? this.compareToErrorMessage
          : null
      }
      if (
        moment(this.compareFromDate, 'YYYY-MM-DD').isAfter(
          moment(this.compareToDate, 'YYYY-MM-DD')
        )
      ) {
        this.compareErrorMessage = 'Invalid range'
      } else {
        this.compareErrorMessage = null
      }
    },
    checkCustomRange() {
      if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM') ===
          moment().format('YYYY-MMM') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
          moment().format('YYYY-MMM')
      ) {
        this.customRangevalue = 'Current month'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM') ===
          moment().subtract(1, 'month').format('YYYY-MMM') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
          moment().subtract(1, 'month').format('YYYY-MMM')
      ) {
        this.customRangevalue = 'Previous month'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM') ===
          moment().subtract(11, 'month').format('YYYY-MMM') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
          moment().format('YYYY-MMM')
      ) {
        this.customRangevalue = 'Last 12 months'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM') ===
          moment().subtract(23, 'month').format('YYYY-MMM') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
          moment().format('YYYY-MMM')
      ) {
        this.customRangevalue = 'Last 24 months'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM') ===
          moment().subtract(1, 'year').set('month', 0).format('YYYY-MMM') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
          moment().subtract(1, 'year').set('month', 11).format('YYYY-MMM')
      ) {
        this.customRangevalue = 'Previous year'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM') ===
          moment().startOf('year').format('YYYY-MMM') &&
        moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
          moment().endOf('month').format('YYYY-MMM')
      ) {
        this.customRangevalue = 'Current year'
      } else if (
        moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM') ===
          moment(this.subscribedRange[0]).format('YYYY-MMM') &&
        (moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
          moment(this.subscribedRange[1]).format('YYYY-MMM') ||
          moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
            moment().format('YYYY-MMM'))
      ) {
        this.customRangevalue = 'All time'
      } else {
        this.customRangevalue = ''
      }
    },
    addAttributeToShortcut() {
      document
        .querySelectorAll('.mx-datepicker-main .mx-datepicker-sidebar button')
        .forEach((btn) => {
          const dataText = btn.innerText
          if (dataText === 'All time') {
            btn.classList.add('hrule')
          }
        })
    },
    disabledOutsideSubs(date) {
      const today = moment().format('YYYY-MM-DD')
      const tempDate = moment(date).format('YYYY-MM-DD')
      return (
        tempDate > today ||
        tempDate <
          moment(this.subscriptionRange.startDate)
            .startOf('month')
            .format('YYYY-MM-DD') ||
        tempDate >
          moment(this.subscriptionRange.endDate)
            .endOf('month')
            .format('YYYY-MM-DD')
      )
      // return 0
    },
    getActiveTimeObj(granularity, range) {
      const timeObject = {}
      timeObject.group_by = granularity
      timeObject.range = range
      return timeObject
    },
    // Based on which module is selected
    updateTimePeriod() {
      const timeObject = {}
      timeObject.group_by = this.ActiveTimePeriod.group_by
      this.date[1] = moment(this.date[1]).endOf('month').format('YYYY-MM-DD')
      timeObject.range = this.date
      if (this.currentModule === 'voe') {
        this.updateActiveVoeTimePeriod(timeObject)
      } else if (this.currentModule === 'voc' && this.currentPath === 'vendor-csat') {
        this.updateActiveVendorCsatTimePeriod(timeObject)
      } else {
        this.updateActiveVocTimePeriod(timeObject)
      }
    },
    updateGranularity(granularity) {
      const obj = this.getActiveTimeObj(
        granularity,
        this.ActiveTimePeriod.range
      )
      if (this.currentModule === 'voe') {
        this.updateActiveVoeTimePeriod(obj)
      } else if (this.currentModule === 'voc' && this.currentPath === 'vendor-csat') {
        this.updateActiveVendorCsatTimePeriod(obj)
      } else {
        this.updateActiveVocTimePeriod(obj)
      }
    },
    updateRange(type, months, range, granularity) {
      this.date = getCustomDateRange(type, months, range)
      let monthGranularity = '' // this.subscriptionGranularity ? 'month' : 'quarter'
      if (granularity) {
        monthGranularity = granularity
      } else {
        monthGranularity = this.subscriptionGranularity ? 'month' : 'quarter'
      }
      if (this.ActiveTimePeriod && this.ActiveTimePeriod.group_by) {
        monthGranularity = this.ActiveTimePeriod.group_by
      }
      const timeObj = this.getActiveTimeObj(monthGranularity, this.date)
      if (this.currentModule === 'voe') {
        this.updateActiveVoeTimePeriod(timeObj)
      } else if (this.currentModule === 'voc' && this.currentPath === 'vendor-csat') {
        this.updateActiveVendorCsatTimePeriod(timeObj)
      } else {
        this.updateActiveVocTimePeriod(timeObj)
      }
      this.makeButtonActive()
    },
    handelPick(d) {
      this.addEventListener(d)
    },
    makeButtonActive() {
      if (this.selectedButton !== 'allTime') {
        if (
          moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM') ===
            moment().format('YYYY-MMM') &&
          moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
            moment().format('YYYY-MMM')
        ) {
          this.selectedButton = 'currentMonth'
        } else if (
          moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM') ===
            moment().subtract(1, 'month').format('YYYY-MMM') &&
          moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
            moment().subtract(1, 'month').format('YYYY-MMM')
        ) {
          this.selectedButton = 'previousMonth'
        } else if (
          moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM') ===
            moment().subtract(11, 'month').format('YYYY-MMM') &&
          moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
            moment().format('YYYY-MMM')
        ) {
          this.selectedButton = 'last12Months'
        } else if (
          moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM') ===
            moment().subtract(23, 'month').format('YYYY-MMM') &&
          moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
            moment().format('YYYY-MMM')
        ) {
          this.selectedButton = 'last24Months'
        } else if (
          moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM') ===
            moment().subtract(1, 'year').set('month', 0).format('YYYY-MMM') &&
          moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
            moment().subtract(1, 'year').set('month', 11).format('YYYY-MMM')
        ) {
          this.selectedButton = 'previousYear'
        } else if (
          moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM') ===
            moment().startOf('year').format('YYYY-MMM') &&
          moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
            moment().endOf('month').format('YYYY-MMM')
        ) {
          this.selectedButton = 'currentYear'
        } else if (
          moment(this.ActiveTimePeriod.range[0]).format('YYYY-MMM') ===
            moment(this.subscribedRange[0]).format('YYYY-MMM') &&
          (moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
            moment(this.subscribedRange[1]).format('YYYY-MMM') ||
            moment(this.ActiveTimePeriod.range[1]).format('YYYY-MMM') ===
              moment().format('YYYY-MMM'))
        ) {
          this.selectedButton = 'allTime'
        } else {
          this.selectedButton = ''
        }
      }
      const check = this.calendarOptions.filter(
        ({ value }) => value === this.selectedButton
      )
      if (this.$refs.rangePicker.$refs.popup.$el.firstElementChild) {
        for (const button of this.$refs.rangePicker.$refs.popup.$el.firstElementChild.querySelectorAll(
          'button'
        )) {
          if (check.length && button.innerHTML === check[0].key) {
            button.classList.add('active')
          } else {
            button.classList.remove('active')
          }
        }
      }
      this.selectedButton = ''
    },
    addEventListener(pickedDate) {
      document.querySelectorAll('.cell div').forEach((item) => {
        item.addEventListener(
          'mouseover',
          function (event) {
            // highlight the mouseover target
            if (event.target.parentElement.tagName === 'TD') {
              // document.getElementsByClassName('cell').forEach(item2 => {
              const cells = document.getElementsByClassName('cell')
              for (let i = 0; i < cells.length; i++) {
                const item2 = cells[i]
                const isDisabled = item2.classList.contains('disabled')
                const hoveredMonth =
                  event.target.parentElement.getAttribute('data-month')
                const hoveredYear =
                  event.target.parentElement.getAttribute('year-text')
                const itemYear = item2.getAttribute('year-text')
                const itemMonth = item2.getAttribute('data-month')
                const itemDate = moment(
                  +itemMonth + 1 + ' ' + itemYear,
                  'M YYYY'
                )
                const pickedDateM = moment(pickedDate)
                const hoveredDate = moment(
                  +hoveredMonth + 1 + ' ' + hoveredYear,
                  'M YYYY'
                )
                const lower = pickedDateM.isBefore(hoveredDate)
                  ? pickedDateM
                  : hoveredDate // Math.min(pickedMonth, hoveredMonth)
                const upper = pickedDateM.isBefore(hoveredDate)
                  ? hoveredDate
                  : pickedDateM // Math.max(pickedMonth, hoveredMonth)
                if (
                  !isDisabled &&
                  (moment(itemDate).isBetween(lower, upper) ||
                    lower.isSame(itemDate) ||
                    upper.isSame(itemDate))
                ) {
                  item2.classList.add('in-range')
                } else {
                  item2.classList.remove('in-range')
                }
              }
              // })
            }
          },
          true
        )
      })
      document.querySelectorAll('.cell').forEach((item) => {
        item.addEventListener(
          'mouseover',
          function (event) {
            // highlight the mouseover target
            if (event.target.tagName === 'TD') {
              // document.getElementsByClassName('cell').forEach(item2 => {
              const cells = document.getElementsByClassName('cell')
              for (let i = 0; i < cells.length; i++) {
                const item2 = cells[i]
                const isDisabled = item2.classList.contains('disabled')
                const hoveredMonth = event.target.getAttribute('data-month')
                const hoveredYear = event.target.getAttribute('year-text')
                const itemYear = item2.getAttribute('year-text')
                const itemMonth = item2.getAttribute('data-month')
                const itemDate = moment(
                  +itemMonth + 1 + ' ' + itemYear,
                  'M YYYY'
                )
                const pickedDateM = moment(pickedDate)
                const hoveredDate = moment(
                  +hoveredMonth + 1 + ' ' + hoveredYear,
                  'M YYYY'
                )
                const lower = pickedDateM.isBefore(hoveredDate)
                  ? pickedDateM
                  : hoveredDate // Math.min(pickedMonth, hoveredMonth)
                const upper = pickedDateM.isBefore(hoveredDate)
                  ? hoveredDate
                  : pickedDateM // Math.max(pickedMonth, hoveredMonth)
                if (
                  !isDisabled &&
                  (moment(itemDate).isBetween(lower, upper) ||
                    lower.isSame(itemDate) ||
                    upper.isSame(itemDate))
                ) {
                  item2.classList.add('in-range')
                } else {
                  item2.classList.remove('in-range')
                }
              }
              // })
            }
          },
          true
        )
      })
    },
    handleRangeClose() {
      this.calanderOpened = false
    },
    prepareYearAttr() {
      this.calanderOpened = true
      setTimeout(() => {
        this.addAttributeToShortcut()
      }, 5)
      setTimeout(() => {
        const cells = document.getElementsByClassName('cell')
        for (let i = 0; i < cells.length; i++) {
          const item2 = cells[i]
          const text = d3
            .select(item2.closest('.mx-calendar-panel-month'))
            .select('.mx-calendar-header .mx-calendar-header-label')
            .text()
          item2.setAttribute('year-text', text.trim())
        }
        this.makeButtonActive()
      }, 10)
      this.updateEvent({
        userId: this.userDetails.userId,
        event: 'click',
        label: analyticsEvents['Time Period'],
        pageUrl: window.location.href
      })
    }
  }
}
</script>

<style lang="scss">
.month-range-picker
  .select {
    select {
      // background-color: transparent !important;
      border: 0.8px solid var(--datepicker-border-color) !important;
      border-radius: 4px !important;
      padding: 5px 10px;
      height: 36px;
      color: #eff3f6b2 !important;
      font-size: 14px;
      width: 100%;
      box-shadow: none !important;
      option {
        background-color: var(--hover-sidebar-option-background-color) !important;
        color: #eff3f6b2 !important;
      }
    }
  }
  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: #a8aab7 !important;
    right: 1.125em;
    z-index: 4;
  }
  option {
    padding: 10px !important;
  }
  .select select:focus,
  .taginput .taginput-container.is-focusable:focus,
  .textarea:focus,
  .input:focus,
  .select select.is-focused,
  .taginput .is-focused.taginput-container.is-focusable,
  .is-focused.textarea,
  .is-focused.input,
  .select select:active,
  .taginput .taginput-container.is-focusable:active,
  .textarea:active,
  .input:active,
  .select select.is-active,
  .taginput .is-active.taginput-container.is-focusable,
  .is-active.textarea,
  .is-active.input {
    /* border-color: #7957d5; */
    -webkit-box-shadow: none !important; // 0 0 0 0.125em rgb(121 87 213 / 25%);
    box-shadow: none !important; // 0 0 0 0.125em rgb(121 87 213 / 25%);
  }
  .input-on-calendar {
    //     input[type="date"]::-webkit-calendar-picker-indicator {
    //     display: none !important;
    //     }
    //     input[type="date"]::-webkit-inner-spin-button,
    // input[type="date"]::-webkit-calendar-picker-indicator {
    //     display: none;
    //     -webkit-appearance: none;
    // }
    input {
      color: white;
    }
    ::placeholder {
      color: #ccc;
      opacity: 0.5; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ccc;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #ccc;
    }
  }

  .mx-datepicker {
    width: 100%;
  }

  .mx-datepicker-header {
    text-align: center;
    border-bottom: 1px solid var(--datepicker-border-color);
    font-size: 14px;
    width: 400px;
  }
  .mx-datepicker-popup {
    background-color: var(--sidebar-option-background-color);
    border-radius: 4px;
    color: #eff3f6;
    // width: 732px;
  }
  .mx-range-wrapper .cell {
    &:hover {
      color: #eff3f6;
      background-color: var(--hover-sidebar-option-background-color);
      div {
        // background-color: var(--datepicker-border-color);
        // border-radius: 10px;
        font-weight: bold;
      }
    }
    &:last-child.in-range {
      div {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    &:first-child.in-range {
      div {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
    &.disabled {
      cursor: not-allowed;
      background-color: var(--sidebar-option-background-color);
      &:hover {
        div {
          background-color: var(--hover-sidebar-option-background-color);
        }
      }
      div {
        opacity: 0.5;
      }
    }
    &.active {
      background-color: var(--sidebar-option-background-color);
      div {
        background-color: #2780eb;
        border-radius: 10px;
      }
    }
  }
  .mx-calendar-content table td,
  .mx-calendar-content table th {
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }

  .mx-calendar-content .cell.in-range {
    color: #eff3f6;
    background-color: var(--sidebar-option-background-color);
    // &:hover {
    //   background-color: var(--datepicker-border-color);
    //   border-radius: 4px;
    // }
    div {
      background-color: var(--hover-sidebar-option-background-color);
    }
  }
  .mx-calendar {
    width: 200px;
  }
  .mx-calendar-content {
    height: 130px;
  }
  .mx-datepicker-main,
      .mx-datepicker-sidebar + .mx-datepicker-content,
      // .mx-calendar + .mx-calendar
  {
    border: 1px solid var(--datepicker-border-color);
  }
  .mx-calendar + .mx-calendar {
    border-left: 1px solid var(--datepicker-border-color);
  }
  .mx-datepicker-footer {
    border-top: 1px solid var(--datepicker-border-color);
    width: 400px;
    .period-selector {
      text-align: left;
      position: absolute;
      left: 530px;
      top: -1px;
      width: 200px;
      height: calc(100% + 2px);
      background-color: var(--sidebar-option-background-color);
      // background-color: var(--tooltip-background-color);
      border: 1px solid var(--datepicker-border-color);
      // border: none;
      z-index: 99;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      padding: 5px 20px;
      // box-shadow: 0px 3px 6px #00000029;
      color: #eff3f6;
      font-family: 'Quicksand';
      font-size: 12px;
      // z-index: 2001;
      font-size: 14px;
      .select select {
        height: 30px;
        width: 150px;
        color: #eff3f6 !important;
      }
      .head {
        // padding: 6px 20px;
        // border-bottom: 1px solid var(--datepicker-border-color);
      }
      .errorMessage {
        display: block;
        position: absolute;
        color: orangered;
        font-size: 12px;
      }
      .from-error {
        top: 112px;
        left: 20px;
      }
      .to-error {
        top: 180px;
        left: 20px;
      }
      .ghost-btn {
        background-color: var(--hover-sidebar-option-background-color) !important;
        border: 0.8px solid #4b586a !important;
        border-radius: 4px;
        color: #ccc;
        font-family: Quicksand;
        margin: 0px 0px;
        &:hover {
          color: #ccc;
          font-weight: 700;
          // border: 0.8px solid #A8AAB7 !important;
        }
        &:active {
          color: #ccc;
          // border: 0.8px solid #A8AAB7 !important;
        }
      }
      .from-input {
        margin-top: 5px;
        margin-bottom: 18px;
        & div:first-child {
          // margin-bottom: 3px;
        }
        input {
          width: 140px;
          height: 30px;
          background: var(--hover-sidebar-option-background-color) 0% 0% no-repeat padding-box;
          border: 1px solid #4b586a;
          border-radius: 0px;
          opacity: 1;
          color: #eff3f6b2;
          font-size: 14px;
        }
        ::placeholder {
          color: #ccc;
          opacity: 0.5; /* Firefox */
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #ccc;
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #ccc;
        }
        .datepart1,
        .datepart3 {
          .select select {
            width: 75px;
            padding-right: 1em;
            border-radius: 0px !important;
          }
          .select:not(.is-multiple):not(.is-loading)::after {
            right: 0.6em;
          }
        }
        .datepart1 {
          margin-right: 0px;
          input {
            width: 45px;
            border-right: none;
          }
          .select select {
            border-right: none !important;
          }
        }
        .datepart3 {
          margin-right: 0px;
          input {
            width: 60px;
            border-left: none;
          }
        }
        .datepart2 {
          margin-right: 0px;
          input {
            width: 45px;
            // border-right: none;
            // border-left: none;
          }
        }
      }
    }
  }
  .mx-datepicker-sidebar + .mx-datepicker-content {
    margin-left: 130px;
  }
  // .mx-datepicker-content {
  //   width: 656px;
  // }
  .mx-datepicker-sidebar {
    padding-left: 2px;
    .mx-btn {
      font-weight: normal;
    }
    .hrule:after {
      content: '';
      position: absolute;
      width: 126px;
      height: 0.5px; // suit your need
      background: var(--datepicker-border-color); // suit your need
      top: 30px;
      left: 0;
      // margin-top: 2px;
      // margin-bottom: 2px;
    }
    .hrule {
      position: relative;
      margin-bottom: 15px;
    }
  }

  .mx-input { //Trial
    cursor: pointer;
    border: none;
    font-family: Quicksand;
    width: 100%;
    font-size: 11px;
    // font-size: 14px;
    height: 24px;
    // height: 27px;
    color: #eff3f6cc;
    display: flex;
    align-items: center;
    background-color: rgba(196, 200, 248, 0.2);
    @media (min-width: 1450px) and (max-width: 1700px) {
      height: 27px;
      font-size: 12px;
      font-family: Quicksand;
    }
    @media (min-width: 1700px) {
      height: 33px;
      font-size: 14px;
      font-family: Quicksand;
    }
    &:hover {
      outline: none;
    }
  }
  .mx-icon-calendar {
    color: rgb(168, 170, 183);
  }

  .mx-datepicker-sidebar {
    width: 130px;
    .active {
      background-color: var(--hover-sidebar-option-background-color);
    }
  }

  .calandedr-text,
  .mx-btn-shortcut,
  .mx-btn-text {
    color: #eff3f6;
  }

  .mx-icon-single-left,
  .mx-icon-single-right {
    display: inline;
  }
  tr td.in-range {
    // &:last-child {
    //   background-color: red;
    // }
    div {
      background-color: var(--sidebar-option-background-color);
    }
    // border-radius: 10px;
  }
  .mx-datepicker-main {
    font-size: 12px;
    font-family: Quicksand;
  }
  .radio {
    font-size: 14px;
    &:hover {
      color: #eff3f6;
      // text-decoration: underline;
    }
  }
  .mx-btn-shortcut {
    width: 100%;
    &:hover {
      color: #eff3f6;
      background-color: var(--hover-sidebar-option-background-color);
    }
  }
</style>
<style lang="scss" src="./common.scss" scoped />
