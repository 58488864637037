<template>
  <div ref="pageTrackingContainer">
    <div v-if="showDefaultPage" class="default-page">
      <div v-if="currentPage == 'trends'" class="category-title mb-2 ">
        Core Capabilities: Comparison over time
      </div>
      <div v-if="currentPage == 'competitive-positioning'" class="category-title">
        Insights
      </div>
      <div v-if="currentPage == 'reports'" class="category-title">
          Reports
      </div>
      <div v-if="currentPage == 'voeReports'" class="category-title">
          Perception Watch
      </div>
      <div v-if="$route.name == 'voeTrends' || $route.path === 'trends'" class="category-title">
          Companies - Key Attributes
        </div>
      <div v-if="withLegends" class="legends-container">
        <div v-if="currentPage == 'competitive-positioning'" class="legends-common">
        <div>
          Sentiment Score <img src="../../assets/info-new.svg" alt="" @mouseenter="tooltip($event, sentimentScoreTitle, 'in', undefined, 'width-300')" @mouseleave="tooltip($event, sentimentScoreTitle, 'out', undefined, 'width-300')" @mousemove="tooltip($event, sentimentScoreTitle, 'in')" class="ml-2" />
        </div>
        </div>
        <template v-if="(currentPage === 'csat' || currentPage === 'voeEsat' || currentPage === 'vendor-csat') && driverView !== 2">
          <div v-if="currentPage === 'csat'" class="category-title">Core Capabilities: Competitive Benchmarking</div>
          <div v-if="currentPage === 'vendor-csat'" class="category-title">Core Capabilities: Competitive Benchmarking by Vendor</div>
          <div v-if="currentPage === 'voeEsat'" class="category-title">Companies - Employee Satisfaction (ESAT) Measure</div>
          <div class="c-sat-driver legends-common">
            <div class="single-element" v-if="(driverView === 0 || driverView === 1) && showTotal">
              <div class="review-bar"></div>
              <p v-if="currentPage === 'csat'">Averages of All Products</p>
              <p v-if="currentPage === 'voeEsat' || currentPage === 'vendor-csat'">Averages of All Companies</p>
            </div>
            <div class="single-element" v-if="driverView === 0">
              <div class="wleveraged opacity-80"></div>
              <p>Well Leveraged</p>
            </div>
            <div class="single-element" v-if="driverView === 0">
              <div class="uleveraged opacity-80"></div>
              <p>Under Leveraged</p>
            </div>
            <div class="single-element" v-if="driverView === 1">
              <div class="review-bar-single"></div>
              <p v-if="currentPage === 'csat'">Averages of Product</p>
              <p v-if="currentPage === 'voeEsat' || currentPage === 'vendor-csat'">Averages of Company</p>
            </div>
            <div class="single-element" v-if="driverView === 1">
              <div class="like"></div>
              <p>Like %</p>
            </div>
            <div class="single-element" v-if="driverView === 1">
              <div class="dislike"></div>
              <p>Dislike %</p>
            </div>
          </div>
        </template>
        <template v-if="( currentPage === 'voeEvpMatrix')">
        <div class="c-sat-driver legends-common">
          <div class="kano-label" v-if="(currentModule === 'voe' && !localCompany)">
            <div class="filled-circle"></div>
            <p>All Companies</p>
          </div>
          <div class="kano-label" v-if="(currentModule === 'voe' && localCompany)">
            <div class="filled-circle"></div>
            <p>{{ localSelectedCompany.name }}</p>
          </div>
          <div class="kano-label" v-if="(currentModule === 'voe' && localCompany)">
            <div class="bordered-circle"></div>
            <p>Other Companies</p>
          </div>
        </div>
        </template>
        <template v-if="(currentPage === 'csat' ) && driverView === 2">
          <div class="category-title" v-if="currentPage === 'csat'">Core Capabilities: Prioritization Matrix <img src="../../assets/info-new.svg" alt="" class="pl-2" @mouseenter="tooltip($event, priorMatrixTitle, 'in')" @mouseleave="tooltip($event, priorMatrixTitle, 'out')" @mousemove="tooltip($event, priorMatrixTitle, 'in')" /></div>
        <div class="c-sat-driver legends-common">
          <div class="kano-label" v-if="(currentModule === 'voc' && !localProduct)">
            <div class="filled-circle"></div>
            <p>All Products</p>
          </div>
          <div class="kano-label" v-if="(currentModule === 'voc' && localProduct)">
            <div class="filled-circle"></div>
            <p>{{ localSelectedProduct.name }}</p>
          </div>
          <div class="kano-label" v-if="(currentModule === 'voc' && localProduct)">
            <div class="bordered-circle"></div>
            <p>Other Products</p>
          </div>
        </div>
        </template>
        <div class="legend-inside legends-common" v-if="currentPage == 'reports'">
          <div class="left-legends">
            <div
              class="legend"
              v-for="(product, index) in selectedProducts"
              :key="'report-legend' + index">
              <span
                class="colored-rect"
                v-bind:style="{ background: itemColors(index) }">
              </span>
              <p>{{ product.name }}</p>
            </div>
          </div>
          <div class="right-fix-legends">
            <div class="seperator"></div>
            <div class="legend">
              <img
                src="@/assets/red-dashed-line.svg"
                alt="d"
                class="dashed-image" />
              <p>Overall Avg. rating</p>
            </div>
            <div class="legend">
              <span class="line"></span>
              <p>All time product Avg. rating</p>
            </div>
          </div>
        </div>
        <div class="legend-inside legends-common" v-if="currentPage == 'voeReports'">
          <div class="left-legends">
            <div
              class="legend"
              v-for="(company, index) in selectedCompaniesVoe"
              :key="'report-legend' + index">
              <span
                class="colored-rect"
                v-bind:style="{ background: itemColors(index) }">
              </span>
              <p>{{ company.name }}</p>
            </div>
          </div>
          <div class="right-fix-legends">
            <div class="seperator"></div>
            <div class="legend">
              <img
                src="@/assets/red-dashed-line.svg"
                alt="d"
                class="dashed-image" />
              <p>Overall Avg. rating</p>
            </div>
            <div class="legend">
              <span class="line"></span>
              <p>All time company Avg. rating</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import tooltip from '../../util/tooltip'
import { itemColors } from '@/util/util.js'

/**
 * This component renders the heading/title for the views. The rendered data is visible under the local tabs, and above the actual component data. Along with the heading, this component can also include the legends for the page.
 */
export default {
  name: 'PageTracking',
  props: {
    /**
     * Indicates whether this component has legends available to display
     */
    withLegends: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultPageRequiredList: [
        'trends',
        'competitive-positioning',
        'reports',
        'voeReports',
        'csat',
        'voeEsat',
        'vendor-csat',
        'voeEvpMatrix',
        'voeTrends'
      ],
      sentimentScoreTitle:
        ' Sentiment score is used in the competitive positioning section to transform the aspect-based sentiment analysis in the reviews into a polarity-precision sentiment analysis. The sentiment score gauges the reviewer\'s reaction to the feature implementation in the product and its impact on the reviewer\'s overall product satisfaction. The sentiment scale used for scoring is on a scale of -5 to +5, where -5 depicts a strong negative impact, and +5 depicts a strong positive impact on the overall product satisfaction.',
      priorMatrixTitle: 'The Prioritization matrix derives from the Kano analysis model to genrerate insights by combining user-satisfaction across reviews and maturity of feature implementation across products, thereby allowing for roadmap prioritization and product strategy decision-making'
    }
  },
  computed: {
    ...mapState('filters', ['selectedProducts', 'selectedCategories', 'selectedCompaniesVoe']),
    ...mapState('common', ['user_preference_mode', 'currentModule', 'active_page']),
    ...mapState('vocsummary', ['reviewVolume']),
    ...mapState('client', ['lastUpdatedDateVoc']),
    ...mapState('csatdriver', {
      driverViewVoC: state => state.driverView,
      csatVendorDriverView: state => state.csatVendorDriverView,
      localSelectedCompanyVoC: state => state.localSelectedCompany,
      localSelectedProduct: state => state.localSelectedProduct,
      showTotalVoC: state => state.showTotal,
      showVendorCsatTotal: state => state.showVendorCsatTotal
    }),
    ...mapState('esatdriver', {
      driverViewVoE: state => state.driverView,
      localSelectedCompanyVoE: state => state.localSelectedCompany,
      showTotalVoE: state => state.showTotal
    }),
    currentPage () {
      return this.active_page
    },
    showDefaultPage () {
      return this.defaultPageRequiredList.includes(this.active_page)
    },
    driverView () {
      if (this.currentModule === 'voc') {
        if (this.active_page === 'vendor-csat') return this.csatVendorDriverView
        return this.driverViewVoC
      } else {
        return this.driverViewVoE
      }
    },
    localSelectedCompany () {
      if (this.currentModule === 'voc') {
        return this.localSelectedCompanyVoC
      } else {
        return this.localSelectedCompanyVoE
      }
    },
    showTotal () {
      if (this.currentModule === 'voc') {
        if (this.active_page === 'vendor-csat') return this.showVendorCsatTotal
        return this.showTotalVoC
      } else {
        return this.showTotalVoE
      }
    },
    localProduct () {
      const hasLength = (obj) => {
        for (const i in obj) {
          return true
        }
        return false
      }
      return hasLength(this.localSelectedProduct)
    },
    localCompany () {
      const hasLength = (obj) => {
        for (const i in obj) {
          return true
        }
        return false
      }
      return hasLength(this.localSelectedCompany)
    }
  },
  mounted () {
    this.getLastUpdatedDateVoc()
  },
  updated () {
    const container = this.$refs.pageTrackingContainer
    document.querySelector(':root').style.setProperty('--page-tracking-height', container.clientHeight + 'px')
  },
  methods: {
    ...mapActions('client', ['getLastUpdatedDateVoc']),
    tooltip,
    itemColors
  }
}
</script>

<style lang="scss" scoped src="./PageTracking.scss" />
