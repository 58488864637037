<template>
  <div class="powered-by" :class="[isCollapsed ? 'hidden' : '']">
    <div class="power-logo">
      <img :src="poweredByLogo" alt="iNAVA">
    </div>
    <!-- <span v-if="client_logos && client_logos.show_powered_by_text" class="power-text">{{client_logos.powered_by_text || 'Powered by: SG Analytics Pvt. Ltd.'}}</span> -->
    <span class="power-text">{{ poweredByText }}</span>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'PoweredBy',
  data () {
    return {
      poweredByLogo: null,
      poweredByText: ''
    }
  },
  computed: {
    ...mapState('common', ['isCollapsed']),
    ...mapState('user', ['client_logos'])
  },
  watch: {
    client_logos () {
      this.poweredByLogo = this.client_logos.product_logo_dark || require('@/assets/inava_logo_dark.svg')
      if (this.client_logos && this.client_logos.show_powered_by_text !== undefined && this.client_logos.show_powered_by_text !== null) {
        if (this.client_logos.show_powered_by_text) {
          this.poweredByText = this.client_logos.powered_by_text || 'Powered by: SG Analytics Pvt. Ltd.'
        }
      } else {
        this.poweredByText = 'Powered by: SG Analytics Pvt. Ltd.'
      }
    }
  },
  mounted () {
    this.getClientLogos()
  },
  methods: {
    ...mapActions('user', ['getClientLogos'])
  }
}
</script>

<style lang="scss" scoped>
.powered-by {
  color: #C7C7C7;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
  font-size: 11px;
  font-family: Quicksand, sans-serif;
  .power-text {
    font-size: 10px;
    display: block;
    opacity: 1;
    margin-top: 5px;
    line-height: 14px;
  }
  .power-logo {
    display: flex;
    // margin-top: 25px;
    img {
      width: 45px;
      aspect-ratio: 55 / 19;
      object-fit: contain;
    }
  }
}

.hidden {
  display: none;
}

@media screen and (min-width: 1700px) {
  .powered-by {
    padding: 20px 10px;
    font-size: 11px;
    .power-text {
      margin-top: 10px;
      font-size: 11px;
    }
    .power-logo {
      img {
        width: 55px;
        height: 19px;
        object-fit: contain;
      }
    }
  }
}
</style>
