<template>
  <side-panel-vue>
    <FilterHeader v-if="active_page !== 'profile'" />
    <div
      v-if="active_page !== 'profile'"
      class="filter-container"
      :class="[isCollapsed ? 'collapsed' : '']"
    >
      <div class="each-dropdown">
        <MultiSelectorVue
          label="Company"
          placeholder="Select company"
          :list="companyList"
          :selected="selectedCompaniesVoe"
          @onChange="onChangeCompany"
          :sidebarHeightApplied="true"
        />
      </div>
      <div class="each-dropdown">
        <MultiSelectorVue
          label="Employee Status"
          placeholder="Select employee status"
          :list="employeeStatusList"
          :selected="selectedEmployeeStatuses"
          @onChange="onChangeEmployeeStatus"
          :sidebarHeightApplied="true"
        />
      </div>
      <div class="each-dropdown">
        <MultiSelectorVue
          label="Business Function"
          placeholder="Select business function"
          :list="businessFuncList"
          :selected="selectedBusinessFunc"
          @onChange="onChangeBusinessFunction"
          :sidebarHeightApplied="true"
        />
      </div>
      <div class="each-dropdown">
        <MultiSelectorVue
          label="Seniority Level"
          placeholder="Select seniority level"
          :list="seniorityLevelList"
          :selected="selectedSeniorityLevel"
          @onChange="onChangeSeniorityLevel"
          :sidebarHeightApplied="true"
        />
      </div>
      <div class="each-dropdown month-picker">
        <MonthRangePicker :key="monthPickerKey" />
      </div>
    </div>
  </side-panel-vue>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FilterHeader from '@/components/GlobalFilters/FilterHeader.vue'
import MultiSelectorVue from '@/components//Dropdowns/MultiSelector.vue'
import SidePanelVue from '@/components/Common/SidePanel/SidePanel.vue'
import MonthRangePicker from '@/components/Dropdowns/MonthRangePicker.vue'

/** This component contains the filters in the side panel */
export default {
  name: 'GlobalFilters',
  components: {
    FilterHeader,
    MultiSelectorVue,
    MonthRangePicker,
    SidePanelVue
  },
  data() {
    return {
      timePeriodList: [],
      toggle: false,
      monthPickerKey: 1,
      sidebarHeightApplied:true,
    }
  },
  computed: {
    ...mapState('filters', [
      'companyList',
      'selectedCompaniesVoe',
      'isInitialLoadDone',
      'subscriptionRange',
      'selectedEmployeeStatuses',
      'employeeStatusList',
      'businessFuncList',
      'selectedBusinessFunc',
      'seniorityLevelList',
      'selectedSeniorityLevel'
    ]),
    ...mapState('user', ['userDetails']),
    ...mapState('common', ['active_page', 'isCollapsed'])
  },
  watch: {
    companyList(newValue) {
      if (newValue.length) {
        this.getTimeSubscriptionForVoe()
      }
    },
    subscriptionRange() {
      this.monthPickerKey += 1
    }
  },
  mounted() {
    if (this.userDetails) {
      this.getDataForVoeGlobalFilters(this.userDetails.userId)
    }
  },
  methods: {
    ...mapActions('filters', [
      'getDataForVoeGlobalFilters',
      'updateIsInitialLoadDone',
      // 'updateActiveVoeTimePeriod',
      'updateSortedCompaniesVoe',
      'updateSelectedCompaniesVoe',
      'getTimeSubscriptionForVoe',
      'updateSelectedEmployeeStatuses',
      'updateSelectedBusinessFunctions',
      'updateSelectedSeniorityLevels'
    ]),
    /** This event handler updates the selected companies
     * @public
     */
    onChangeCompany(data) {
      this.updateSortedCompaniesVoe(data)
      this.updateSelectedCompaniesVoe(data)
    },
    /** This event handler updates the selected employee status
     * @public
     */
    onChangeEmployeeStatus(data) {
      this.updateSelectedEmployeeStatuses(data)
    },
    /** This event handler updates the selected employee status
     * @public
     */
    onChangeBusinessFunction(data) {
      this.updateSelectedBusinessFunctions(data)
    },
    /** This event handler updates the selected employee status
     * @public
     */
    onChangeSeniorityLevel(data) {
      this.updateSelectedSeniorityLevels(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.side-panel-container {
  height: 100%;
  background: var(--sidebar-background-color);
  .product-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    img {
      width: 60px;
      object-fit: contain;
      height: 100%;
    }
  }
  .side-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .filter-container {
      overflow-y: auto;
      .each-dropdown {
        padding: 10px 20px;
        min-width: max-content; // This is to prevent displacement of filter text when the sidepanel is expanded
      }
    }
    .collapsed {
      // opacity: 0;
      display: none;
      // transition: 0.225s ease-out 0.075s;
    }
  }
}
</style>
