<template>
    <div class="custom-tabs-main">
    <div class="custom-tabs-container">
        <div class="custom-tabs">
            <div @click="onClick(alias)" v-for="({name,alias,light,dark},index) of list" :key="index" :class="['tab-index',active_page==alias?'active':'']">
                <img svg-inline :src="active_page==alias?dark:light" />
                <p>{{name}}</p>
                <div v-if="active_page==alias"></div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

/**
 * The second row of tabs, used to switch between views within a larger logical group.
 */
export default {
  props: {
    /** The list of tab names */
    list: {
      type: Array
    }
  },
  data () {
    return {
      // tabs: this.list,
      active: ''
    }
  },
  computed: {
    ...mapState('common', ['user_preference_mode']),
    ...mapState('common', ['active_page'])
  },
  methods: {
    /** This method is used to navigate the user to the page whose link/tab was clicked upon */
    onClick (routeName) {
      this.$router.push({ name: routeName })
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-tabs-main {
    .custom-tabs-container {
    .custom-tabs {
    display: flex;
    padding: 0px 20px;
    height: 36px;
    background: var(--primary);
    .tab-index {
            position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0px 10px 0px 10px;
        p {
            color: #7f8fa4;
            font-family: 'ROBOTO';
            font-size: 12px;
            padding-left: 5px;
        }
        .icons path {
        fill: #3c90f5;
      }
    }
    .active {
      div {
          position: absolute;
          left: 0px;
          right: 0px;
          height: 1px;
          background: #2780EB;
          bottom: 0px;
      }
      p {
        color: #2780EB;
        font-weight: bold;
      }
      }
}
}
}

</style>
